import store from "@/store";
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import MessageView from "../views/message/MessageView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "你的极客世界" },
  },
  {
    path: "/user",
    name: "User",
    children: [
      {
        path: "login",
        name: "Login",
        meta: { title: "登录" },
        component: () => import("@/views/user/LoginView.vue"),
      },
      {
        path: "register",
        name: "Register",
        meta: { title: "注册" },
        component: () => import("@/views/user/RegisterView.vue"),
      },
      {
        path: "center/:id/:type",
        name: "PersonalCenterView",
        meta: { title: "个人中心" },
        component: () =>
          import("@/views/user/personal-center/PersonalCenterView.vue"),
      },
    ],
  },
  {
    path: "/community",
    name: "Community",
    children: [
      {
        path: "center",
        name: "CommunityCenter",
        meta: { title: "社区" },
        component: () => import("@/views/community/CommunityCenterView.vue"),
      },
      {
        path: "create",
        name: "CommunityCreate",
        meta: { title: "创建社区" },
        component: () => import("@/views/community/CommunityEditView.vue"),
      },
      {
        path: "manage/:id",
        name: "CommunityManage",
        meta: { title: "管理社区" },
        component: () => import("@/views/community/CommunityManageView.vue"),
      },
      {
        path: "notice/edit/:communityId",
        name: "CommunityNotice",
        meta: { title: "编辑公告" },
        component: () => import("@/views/community/NoticeEditView.vue"),
      },
      {
        path: "notice/details/:id",
        name: "CommunityNoticedDetails",
        meta: { title: "社区公告" },
        component: () => import("@/views/events/ArticleDetailsView.vue"),
      },
    ],
  },
  {
    path: "/search",
    name: "Search",
    meta: { title: "搜索" },
    component: () => import("@/views/search/Search.vue"),
  },
  {
    path: "/edit/:id",
    name: "ArticleEdit",
    meta: { title: "编辑" },
    component: () => import("@/views/article/ArticleEditView.vue"),
  },
  {
    path: "/article/:id",
    name: "ArticleDetails",
    meta: { title: "文章详情" },
    component: () => import("@/views/article/ArticleDetailsView.vue"),
  },
  {
    path: "/hot/details/:id",
    name: "HotDetails",
    meta: { title: "文章详情" },
    component: () => import("@/views/events/ArticleDetailsView.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    children: [
      {
        path: "list",
        name: "ResourcesList",
        meta: { title: "资源" },
        component: () => import("@/views/resources/ResourcesView.vue"),
      },
      {
        path: "edit/:id",
        name: "ResourcesAdd",
        meta: { title: "资源分享" },
        component: () => import("@/views/resources/ResourcesEditView.vue"),
      },
    ],
  },
  {
    path: "/ranking",
    name: "Ranking",
    meta: { title: "排行榜" },
    component: () => import("@/views/ranking/RankingView.vue"),
  },
  {
    path: "/message",
    name: "Message",
    meta: { title: "消息" },
    component: MessageView,
    children: [
      // {
      //   path: "list",
      //   name: "MessageList",
      //   meta: { title: "消息列表" },
      //   component: () => import("@/views/message/component/MessageList.vue"),
      // },
      {
        path: "details/:id",
        name: "MessageDetails",
        meta: { title: "消息详情" },
        component: () => import("@/views/message/component/MessageDetail.vue"),
      },
    ],
  },

  {
    path: "/events",
    name: "Events",
    meta: { title: "活动赛事" },
    component: () => import("@/views/events/EventsView.vue"),
  },

  {
    path: "/about",
    name: "about",
    meta: { title: "关于我们" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/AboutView.vue"),
  },
  // 404 页面
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/error/404.vue"),
    meta: { title: "404" },
  },
  { path: "/:pathMatch(.*)", redirect: "/404", name: "notMatch" },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // @ts-ignore
  window.document.title = to.meta.title;
  next();
});

export default router;
