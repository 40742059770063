<template>
  <a-spin tip="加载中..." :spinning="loading">
    <div class="message-wrap">
      <a-row style="width: 100%; height: 100%" justify="center">
        <a-col :xs="0" :xl="15">
          <div style="height: 20px"></div>
        </a-col>
        <a-col :xs="24" :xl="15" :xxl="12">
          <a-row style="width: 100%; height: 100%" justify="center">
            <a-col :xs="24" :lg="7" :xl="7" :xxl="6">
              <MessageList @chooseUser="chooseUser" :userList="userList" />
            </a-col>
            <a-col :xs="0" :lg="17" :xl="17" :xxl="17">
              <MessageDetail
                @sendMessage="sendMessage"
                :cntUser="cntUser"
                :msgData="msgData"
              />
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script setup lang="ts">
import MessageList from "@/views/message/component/MessageList.vue";
import MessageDetail from "@/views/message/component/MessageDetail.vue";
import {
  sendWebSocket,
  creatWebSocket,
  websocketonmessage,
} from "@/service/websocket/websocket";
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import {
  MsgControllerService,
  UserControllerService,
} from "../../../generated";
import { useRoute } from "vue-router";
import user from "@/store/user";
import router from "@/router";
const store = useStore();
const loginUser = ref();
const ws = ref();
const loading = ref(true);
let getMessage;
const msgData = ref({});
const ids = ref([]);
const userList = ref([]);
const chatData = ref([]);
const route = useRoute();
// 监听连接事件
onMounted(async () => {
  loginUser.value = store.state.user.loginUser;
  if (loginUser.value?.id) {
    MsgControllerService.getMyMsgUsingGet()
      .then((res) => {
        if (res.code == 0) {
          console.log("获取聊天记录", res);
          chatData.value = res.data;
          chatData.value.forEach((chat: any) => {
            if (chat?.userVO) {
              chat.userVO["lastMsg"] =
                chat.msgList[chat.msgList.length - 1]["content"];
            }
            let count = 0;
            chat?.msgList.forEach((msg: Object) => {
              if (msg.status == 0 && msg.toId == loginUser.value.id) {
                count++;
              }
            });
            chat.userVO["unreadNum"] = count;
            userList.value.push(chat?.userVO);
          });
          store.state.userList.userList = userList.value;
        }
        // else {
        //   message.warning(res.message);
        // }
      })
      .then(async (response) => {
        ws.value = store.state.websocket.websocket;
        if (!ws.value) {
          await store.dispatch("websocket/createConnect");
          ws.value = store.state.websocket.websocket;
        }
        setTimeout(() => {
          if (ws.value) {
            ws.value.onmessage = (e: any) => {
              const newMsg = JSON.parse(e.data);
              console.log(newMsg);
              if (newMsg.code == 200) {
                console.log("收到消息", newMsg.data);
                if (newMsg.data.onlineUserList) {
                  ids.value = newMsg.data.onlineUserList;
                  userList.value.forEach((user) => {
                    if (ids.value.includes(user.id)) {
                      user["online"] = true;
                    } else {
                      user["online"] = false;
                    }
                  });
                  store.state.userList.userList = userList.value;
                }
                if (newMsg.data.msg) {
                  console.log("msg", newMsg.data.msg);
                  chatData.value.forEach((chat) => {
                    if (newMsg.data.msg?.fromId == chat?.userVO?.id) {
                      chat.msgList.push(newMsg.data.msg);
                      userList.value.forEach((user) => {
                        if (user.id == newMsg.data.msg.fromId) {
                          user.unreadNum++;
                          user.lastMsg = newMsg.data.msg.content;
                        }
                      });
                    }
                  });
                }
              }
              // msgData.value.push(newMsg);
            };
          }
        }, 200);
      })
      .then(() => {
        const id = route.query.id;
        if (id) {
          UserControllerService.getUserVoByIdUsingGet(id).then((res) => {
            if (res.code == 0) {
              let isExist = false;
              userList.value.forEach((user) => {
                if (user.id == id) {
                  isExist = true;
                  return;
                }
              });
              if (!isExist) {
                let user = res.data;
                user["active"] = true;
                userList.value.unshift(user);
                chatData.value.unshift({
                  msgList: [],
                  userVO: user,
                });
                chooseUser(user);
              }
            } else {
              message.warning(res.message);
            }
          });
        }
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    message.warning("请先登录");
    router.push("/user/login");
    loading.value = false;
  }
});

const msg = ref("");
const sendMessage = (val: string) => {
  if (!cntUser.value?.id) {
    return message.warning("请选择聊天对象");
  }
  if (ws.value) {
    MsgControllerService.sendMessageUsingPost({
      content: val,
      toId: cntUser.value?.id,
      type: "私信",
    }).then((res) => {
      if (res.code == 0) {
        chatData.value.forEach((chat) => {
          if (chat.userVO?.id == cntUser.value?.id) {
            chat?.msgList.push({
              fromId: loginUser.value?.id,
              toId: cntUser.value?.id,
              type: "私信",
              createTime: new Date(),
              updateTime: new Date(),
              status: 0,
              content: val,
              userInfo: loginUser.value,
            });
            userList.value.forEach((user) => {
              if (user.id == cntUser.value.id) {
                user.lastMsg = val;
              }
            });
          }
        });
      } else {
        message.warning(res.message);
      }
    });
  } else {
    message.info("服务未连接");
  }
};
const cntUser = ref({});
const chooseUser = (user: any) => {
  cntUser.value = user;
  msgData.value = chatData.value.filter((chat: any) => {
    return user.id == chat.userVO.id;
  })[0];
};
</script>

<style scoped>
.message-wrap {
  display: flex;
  margin: 0 auto;
  padding-bottom: 100px;
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
