<template>
  <div class="message-details">
    <a-card
      size="small"
      :title="msgData.userVO?.userName || '请选择聊天对象'"
      :body-style="{
        padding: '0px',
        minHeight: '442px',
        background: '#F5F5F5',
      }"
      :head-style="{
        background: '#F5F5F5',
        borderBottom: '2px solid rgba(213,212,211,0.5)',
      }"
    >
      <div class="message-details-list" id="message_details">
        <div
          class="message-details-wrap"
          v-for="item in msgData?.msgList"
          :key="item"
        >
          <div style="text-align: center; margin: 0 auto; font-size: 10px">
            <span style="background: #dadada; color: white; padding: 2px">
              {{ formatDate(item.createTime) }}</span
            >
          </div>
          <div
            v-if="loginUser?.id === item.fromId"
            class="message-details-item right"
          >
            <div class="message-details-msg" style="background: #b4d7ff">
              {{ item.content }}
            </div>
            <UserAvatar :user="loginUser" />
          </div>
          <div v-else class="message-details-item">
            <UserAvatar :user="cntUser" />
            <div class="message-details-msg">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
      <div class="message-details-bottom">
        <div class="message-details-bottom-menu">
          <div class="menu-item">
            <MyEmoji @chooseEmoji="chooseEmoji" />
          </div>
        </div>
        <a-textarea
          class="ant-input-textarea"
          style="background: #f5f5f5; border: none"
          v-model:value="inputContent"
          bordered="false"
          :maxlength="2000"
        />
        <div class="message-details-bottom-send">
          <a-button type="primary" @click="sendMessage">发送</a-button>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, onMounted, watch } from "vue";
import MyEmoji from "@/components/emoji/MyEmoji.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { formatDate } from "@/utils/myTimeUtils";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { debounce, throttle } from "lodash";

const store = useStore();
const loginUser = ref();
const inputContent = ref("");

const props = defineProps({
  msgData: {
    type: Object,
    required: true,
  },
  cntUser: {
    type: Object,
    required: true,
  },
});

onMounted(() => {
  loginUser.value = store.state.user.loginUser;
  if (props.cntUser.value?.id) {
    toBottom();
  }
});

watch(
  () => props.cntUser?.id,
  (newId, oldId) => {
    // 在 props.cntUser?.id 变化后调用 toBottom 方法
    toBottom();
  }
);
const chooseEmoji = (val: any) => {
  inputContent.value = inputContent.value + val;
};
const messageDetail = ref("");
const emit = defineEmits(["sendMessage"]);
const sendMessage = debounce(() => {
  const fn = () => {
    if (inputContent.value == "") return;
    if (!loginUser.value.id) {
      return message.warning("请先登录");
    }
    emit("sendMessage", inputContent.value);
    toBottom();
    inputContent.value = "";
  };
  fn();
}, 500);

const toBottom = () => {
  setTimeout((res) => {
    const messagDetails = document.getElementById("message_details");
    messagDetails.scrollTo(0, messagDetails.scrollHeight);
  }, 200);
};

// 发送消息后，双方窗口变化
// watch(props.msgData, (newVal, oldVal) => {
//   // 在 msgDate 变化后调用 toBottom 函数
//   toBottom();
// });
</script>
<style scoped lang="less">
.message-details {
  background: #f5f5f5;
  .message-details-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 280px;
    overflow-y: auto;
  }

  flex-wrap: wrap;

  .message-details-wrap {
    width: 100%;
    margin-top: 20px;
    .message-details-item {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 15px;

      display: flex;
      .message-details-msg {
        margin-right: 10px;
        margin-left: 10px;
        padding: 10px;
        font-size: 14px;
        border-radius: 5px;
        background: white;
        max-width: 260px;
      }
    }
  }

  .message-details-bottom {
    height: 150px;
    width: 100%;
    padding: 5px;
    border-top: 1.2px solid rgba(213, 212, 211, 0.5);
    position: absolute;
    bottom: 0;

    .message-details-bottom-menu {
      width: 100%;
      height: 30px;

      .menu-item {
        margin-left: 3px;
        font-size: 18px;
      }
    }

    .message-details-bottom-send {
      position: absolute;
      right: 35px;
      bottom: 10px;
    }
  }
}

.message-details-list::-webkit-scrollbar {
  width: 5px;
  height: 20px;
}

.message-details-list::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 设置滚动条背景颜色 */
}

.message-details-list::-webkit-scrollbar-thumb {
  background-color: #8a8f97; /* 设置滚动条滑块颜色 */
  border-radius: 4px; /* 设置滚动条滑块圆角 */
}

.message-details-list::-webkit-scrollbar-thumb:hover {
  background-color: slategrey; /* 设置滚动条滑块在悬停时的颜色 */
}

.right {
  float: right;
  display: flex;
}

.ant-input-textarea {
  height: 80px;
}

.ant-input-textarea:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
</style>
