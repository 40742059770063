/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EmailCodeControllerService {
    /**
     * getCode
     * @returns any OK
     * @throws ApiError
     */
    public static getCodeUsingGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/code/captcha',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * sendEmailCode
     * @param email email
     * @param imageCaptcha imageCaptcha
     * @returns BaseResponse_boolean_ OK
     * @throws ApiError
     */
    public static sendEmailCodeUsingGet(
        email: string,
        imageCaptcha: string,
    ): CancelablePromise<BaseResponse_boolean_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/code/email',
            query: {
                'email': email,
                'imageCaptcha': imageCaptcha,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
