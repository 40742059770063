<template>
  <div class="footer">
    <a-row style="width: 100%" justify="center">
      <a-col :xs="24" :lg="3">
        <div class="geek-info">
          <div class="logo">
            <img style="width: 50px" src="@/assets/images/logo.png" />
            <span class="title">极客工作室</span>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :lg="7">
        <div class="info">
          <p>
            极客工作室是一个由学生自主管理的专门面对于电子信息类学科竞赛培养学生专业知识技能的学科交流型社团
          </p>
          <p>本站原创内容转载请注明来源</p>
          <p>
            <a href="mailto:minjie128@qq.com"
              ><span style="margin: 2px; font-size: 16px"><MailOutlined /></span
              >minjie128@qq.com</a
            >
            <a href="tencent://AddContact/?uin=1966447213" target="_blank"
              ><span style="font-size: 16px"><QqOutlined /></span>1966447213</a
            >
            <a href="https://gitee.com/Minjie_X" target="_blank"
              ><span style="margin: 2px; margin-left: 5px; font-size: 16px"
                ><GithubOutlined /></span
              >冰咖啡</a
            >
          </p>
        </div>
      </a-col>
      <a-col :xs="24" :lg="7">
        <div class="copyright">
          <p>&copy; 2024 极客工作室 版权所有</p>
          <p>
            <a>服务协议</a> | <a>隐私政策</a> |
            <a @click="toPage('/hot/details/1759830757678452738')">关于我们</a>
          </p>
          <p>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              ><img src="@/assets/images/beian.png" />赣ICP备2021005207号-3</a
            >
          </p>
        </div>
      </a-col>
      <a-col :xs="24" :lg="6">
        <div class="friendship">
          <p>
            友情链接：<a href="http://www.xmj123.top" target="_blank"
              >格鲁特导航</a
            >
          </p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import {
  GithubOutlined,
  MailOutlined,
  QqOutlined,
} from "@ant-design/icons-vue";
import router from "@/router";

const toPage = (path: string) => {
  router.push(path);
};
</script>

<style scoped>
.footer {
  text-align: center;
  position: relative;
  bottom: 0;
  /*background: rgba(40, 44, 52, 0.1);*/
  border-top: 1px solid rgba(229, 229, 229, 1);
  padding-top: 20px;
  color: rgba(40, 44, 52, 0.7);
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  color: grey;
  font-size: 12px;

  a {
    font-size: 12px;

    color: grey;
  }
  a:hover {
    color: #2f80ed;
  }
}
.geek-info {
  justify-items: center;
  align-items: center;
  display: flex;
  width: 100%;
  .logo {
    display: flex;
    height: 60px;
    font-size: 15px;
    margin: 0 auto;
    line-height: 60px;
    align-items: center;
    justify-items: center;
    font-weight: bold;
    .title {
      font-family: 幼圆;
    }
    img {
      padding: 5px;
      width: 50px;
      height: 50px;
      opacity: 1;
      margin-top: 5px;
    }
  }
}
.info {
  padding: 10px;
  padding-top: 0;
  min-width: 280px;
  font-size: 12px;
}
.copyright {
  font-size: 12px;
  text-align: center;
  a {
    font-size: 12px;
    margin: 5px;
  }
}
.friendship {
  font-size: 12px;

  a {
    margin: 5px;
  }
}
</style>
