<template>
  <a-card :loading="loading" body-style="padding:16px;">
    <div class="article-details-follow" v-if="!(user?.id == loginUser?.id)">
      <a-button
        v-if="!user?.hasFollow"
        type="primary"
        size="small"
        @click="followUser(user?.id)"
        >关注</a-button
      >
      <a-button
        v-else
        type="primary"
        size="small"
        @click="toPage('/message?id=' + user?.id)"
        >私信</a-button
      >
    </div>
    <div class="article-details-userinfo-top">
      <div class="article-details-userinfo-avatar">
        <a-avatar
          @click="toPage('/user/center/' + user?.id + '/info')"
          shape="square"
          :size="45"
          style="background-color: #079edc; border: none; cursor: pointer"
        >
          <template #icon>
            <template v-if="!user?.userAvatar"
              >{{ user?.userName[0] }}
            </template>
            <img v-else :src="user?.userAvatar"
          /></template>
        </a-avatar>
      </div>
      <div class="article-details-userinfo-details">
        <div class="article-details-userinfo-username">
          {{ user?.userName }}
        </div>
        <div style="font-size: 16px">
          <a-tooltip placement="bottom" v-if="user?.userSex">
            <template #title>
              <span v-if="user?.userSex == 0">性别：女</span>
              <span v-else-if="user?.userSex == 1">性别：男</span>
              <span v-else>未知</span>
            </template>
            <MyIcon type="icon-geeknan" />
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="user?.fullName">
            <template #title>
              <span>已完成实名认证</span>
            </template>
            <MyIcon type="icon-geeklanVrenzheng" />
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="user?.userSchool">
            <template #title>
              <span>校园认证</span>
            </template>
            <MyIcon type="icon-geekzhuanjia" />
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="user?.userRole == 'admin'">
            <template #title>
              <span>管理员</span>
            </template>
            <MyIcon type="icon-geekmianxingwenjianguanliyuan" />
          </a-tooltip>
        </div>
      </div>
    </div>
    <div class="article-details-userinfo-count">
      <div class="article-details-userinfo-count-item">
        <div class="article-details-userinfo-count-item-num">
          {{ user?.postCount ? user?.postCount : 0 }}
        </div>
        <div class="article-details-userinfo-count-item-title">文章</div>
      </div>
      <div class="article-details-userinfo-count-item">
        <div class="article-details-userinfo-count-item-num">
          {{ user?.viewCount ? user?.viewCount : 0 }}
        </div>
        <div class="article-details-userinfo-count-item-title">浏览</div>
      </div>
      <div class="article-details-userinfo-count-item">
        <div class="article-details-userinfo-count-item-num">
          {{ user?.thumbCount ? user?.thumbCount : 0 }}
        </div>
        <div class="article-details-userinfo-count-item-title">获赞</div>
      </div>
      <div class="article-details-userinfo-count-item">
        <div class="article-details-userinfo-count-item-num">
          {{ user?.followerCount ? user?.followerCount : 0 }}
        </div>
        <div class="article-details-userinfo-count-item-title">粉丝</div>
      </div>
    </div>
    <div class="article-details-userinfo-tags">
      兴趣标签：
      <p
        style="margin-top: 5px"
        v-if="!user?.tagList || user?.tagList.length == 0"
      >
        暂无...
      </p>
      <a-tag v-for="item in user?.tagList">{{ item }} </a-tag>
    </div>
  </a-card>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import MyIcon from "@/components/MyIcon.vue";
import { UserFollowControllerService } from "../../generated";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { debounce, throttle } from "lodash";
const router = useRouter();
const store = useStore();
const loginUser = ref({});
const toPage = (path: string) => {
  router.push(path);
};
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
});
onMounted(async () => {
  loginUser.value = await store.state.user.loginUser;
});
const followUser = debounce((id: number) => {
  UserFollowControllerService.doUserFollowUsingPost({ followedUser: id }).then(
    (res) => {
      if (res.code == 0) {
        // user?.hasFollow = true;
        message.success("关注成功");
        router.push("/user/center/" + id + "/info");
      } else {
        message.warning(res.message);
      }
    }
  );
}, 1000);
</script>

<style scoped>
.article-details-follow {
  position: relative;
  right: 0px;
  top: 20px;
  float: right;
}
.article-details-userinfo-top {
  display: flex;

  .article-details-userinfo-avatar {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .article-details-userinfo-details {
    padding-top: 8px;
    margin-left: 10px;
    /*background: red;*/

    .article-details-userinfo-username {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      vertical-align: middle;
    }
  }
}

.article-details-userinfo-count {
  display: flex;

  .article-details-userinfo-count-item {
    width: 70px;
    text-align: center;
    margin: 2px;
  }
}

.article-details-userinfo-tags {
  padding: 0;
  margin-top: 20px;
}

.article-details-related-recommend {
  a {
    color: grey;
  }

  a:hover {
    color: #2f80ed;
  }
}
</style>
