<template>
  <MyIcon @click="handleClick" :type="type"></MyIcon>
</template>

<script>
import { createFromIconfontCN } from "@ant-design/icons-vue";

const MyIcon = createFromIconfontCN({
  // 图标有更新时，需更新下面图标链接
  scriptUrl: "//at.alicdn.com/t/c/font_4425151_ghzzpbuox9l.js",
});
export default {
  name: "my-icon",
  components: { MyIcon },
  props: {
    type: { type: String, default: "icon-geekfabiaoqing", required: true },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped lang="less"></style>
