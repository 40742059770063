// initial state
import { StoreOptions } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";
import { UserControllerService } from "../../generated";
import { message } from "ant-design-vue";

export default {
  namespaced: true,
  state: () => ({
    userList: [],
  }),
  actions: {
    async getUserList({ commit, rootState }, payload) {
      console.log("获取用户列表");
      UserControllerService.listUserVoByPageUsingPost({
        current: 1,
        pageSize: 20,
      }).then((res) => {
        if (res.code == 0) {
          const userList = res.data.records.filter(
            (user: any) => user.id != rootState.user?.loginUser?.id
          );
          commit("updateUserList", userList);
        } else {
          message.warning(res.message);
        }
      });
    },
  },
  mutations: {
    updateUserList(state, payload) {
      state.userList = payload;
    },
  },
} as StoreOptions<any>;
