import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import "./assets/common.less";
import "@/access";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn"; // 导入中文语言包
import VueClipboard from "vue-clipboard2";
// 设置语言为中文
dayjs.locale("zh-cn");
createApp(App).use(store).use(router).use(Antd).use(VueClipboard).mount("#app");
