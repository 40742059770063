<template>
  <a-layout
    :style="{
      background: '#f6f7f8',
    }"
  >
    <a-layout-header class="header" width="100%">
      <HeaderView :loginUser="loginUser" />
    </a-layout-header>
    <a-layout-content>
      <div style="min-height: 600px"><router-view /></div>
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center', background: '#f6f7f8' }">
      <FooterView />
    </a-layout-footer>
  </a-layout>
</template>

<script setup lang="ts">
import FooterView from "../components/FooterView.vue";
import HeaderView from "../components/HeaderView.vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { onMounted, ref } from "vue";
import { OpenAPI } from "../../generated";

const store = useStore();
const loginUser = ref({});
onMounted(async () => {
  const token = OpenAPI.TOKEN;
  if (!token) {
    OpenAPI.TOKEN = "" + localStorage.getItem("token");
  }
  const user = store.state.user.loginUser;
  // // 如果之前没登陆过，自动登录
  if (!user || !user.id) {
    // 加 await 是为了等用户登录成功之后，再执行后续的代码
    await store.dispatch("user/getLoginUser");
    loginUser.value = store.state.user.loginUser;
  } else {
    loginUser.value = user;
  }
});
</script>

<style scoped lang="less">
.header {
  width: 100%;
  padding: 0;
  background: white;
  display: flex;
  justify-content: space-between;
}
</style>
