// initial state
import { StoreOptions } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";
import { TagControllerService, UserControllerService } from "../../generated";

export default {
  namespaced: true,
  state: () => ({
    tagList: [],
  }),
  actions: {
    async getTagList({ commit, state }, payload) {
      await TagControllerService.listTagVoByPageUsingPost({
        communityId: 1,
      }).then((res) => {
        console.log("获取标签", res);
        if (res.code === 0) {
          commit("updateTagList", res.data);
        } else {
          commit("updateTagList", {
            ...state.tagList,
          });
        }
      });
    },
  },
  mutations: {
    updateTagList(state, payload) {
      state.tagList = payload;
    },
  },
} as StoreOptions<any>;
