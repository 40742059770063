/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_List_TagByTypeVO_ } from '../models/BaseResponse_List_TagByTypeVO_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_Page_Tag_ } from '../models/BaseResponse_Page_Tag_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { TagAddRequest } from '../models/TagAddRequest';
import type { TagQueryRequest } from '../models/TagQueryRequest';
import type { TagUpdateRequest } from '../models/TagUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TagControllerService {
    /**
     * addTag
     * @param tagAddRequest tagAddRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addTagUsingPost(
        tagAddRequest: TagAddRequest,
    ): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tag/add',
            body: tagAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * deleteTag
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteTagUsingPost(
        deleteRequest: DeleteRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tag/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listTagByPage
     * @param tagQueryRequest tagQueryRequest
     * @returns BaseResponse_Page_Tag_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listTagByPageUsingPost(
        tagQueryRequest: TagQueryRequest,
    ): CancelablePromise<BaseResponse_Page_Tag_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tag/list/page',
            body: tagQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listTagVOByPage
     * @param tagQueryRequest tagQueryRequest
     * @returns BaseResponse_List_TagByTypeVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listTagVoByPageUsingPost(
        tagQueryRequest: TagQueryRequest,
    ): CancelablePromise<BaseResponse_List_TagByTypeVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tag/list/vo/all',
            body: tagQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * updateTag
     * @param tagUpdateRequest tagUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateTagUsingPost(
        tagUpdateRequest: TagUpdateRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tag/update',
            body: tagUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
