<template>
  <template v-if="isBasic">
    <BasicLayout />
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import BasicLayout from "./layouts/BasicLayout.vue";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";

const isBasic = ref(true);
const route = useRoute();

watch(
  () => route.path,
  (newPath) => {
    if (newPath.includes("/login") || newPath.includes("/register")) {
      isBasic.value = false;
    } else {
      isBasic.value = true;
    }
  }
);
</script>

<style>
body {
  background: #f6f7f8;
}
</style>
