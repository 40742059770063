<template>
  <div class="emoji">
    <a-popover v-model:open="visible" trigger="click">
      <template #content>
        <div class="emoji-wrap">
          <a-tabs
            :size="small"
            v-model:activeKey="activeKey"
            :tab-position="tabPosition"
            animated
          >
            <a-tab-pane
              v-for="(emojiGroup, index) in emojiData"
              :key="index"
              :tab="emojiGroup.name"
            >
              <span
                style="width: 20px; height: 20px; cursor: pointer"
                v-for="item in emojiGroup.iconArr"
                :key="item.className"
                @click="clickEmoji(item.icon)"
                >{{ item.icon }}</span
              >
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <MyIcon type="icon-geekbiaoqingbao" />
    </a-popover>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, onMounted, ref, defineExpose } from "vue";
import { emojiData } from "./data/emojiData";
const visible = ref<boolean>(false);
import MyIcon from "@/components/MyIcon.vue";
import { message } from "ant-design-vue";
import { TabsProps } from "ant-design-vue/es/tabs";

const faceList = ref(emojiData);
const tabIndex = ref(0);
const inputVal = ref("");
const emojiHide = () => {
  visible.value = false;
};
const emojiOpen = () => {
  visible.value = true;
};
defineExpose({
  emojiOpen,
});
const tabPosition = ref<TabsProps["tabPosition"]>("top");
const activeKey = ref(0);
// 点击表情包
const tabList = ref([]);

// 点击标签页
const tabClick = (tab: any) => {
  tabIndex.value = tab.index;
};
const emit = defineEmits(["chooseEmoji"]);
const clickEmoji = (val: any) => {
  emit("chooseEmoji", val);
};
</script>

<style scoped lang="less">
.emoji-wrap {
  font-size: 20px;
  width: 320px;
  height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative; /* 确保伪元素定位正确 */
}

.emoji-wrap::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 5px; /* 滚动条的宽度 */
}

.emoji-wrap::-webkit-scrollbar-track {
  /* 滚动条轨道样式 */
  background: #f1f1f1; /* 轨道颜色 */
}

.emoji-wrap::-webkit-scrollbar-thumb {
  /* 滚动条滑块样式 */
  background: #888; /* 滑块颜色 */
}

.emoji-wrap::-webkit-scrollbar-thumb:hover {
  /* 鼠标悬停时滑块样式 */
  background: #555; /* 滑块颜色变化 */
}
</style>
