import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
export const formatDate = (date: any, type = 1) => {
  let formattedTime;
  if (type == 1) {
    // 将 updateTime 转换为相对时间或指定格式的日期和时间
    // 计算时间差
    const diffInDays = dayjs().diff(dayjs(date), "day");

    if (diffInDays <= 3) {
      // 超过三天使用相对时间
      formattedTime = dayjs(date).fromNow();
    } else {
      // 不超过三天使用指定格式的日期和时间
      formattedTime = dayjs(date).format("YYYY年M月D日HH:mm:ss");
    }
  } else if (type == 2) {
    formattedTime = dayjs(date).format("YYYY年M月D日");
  } else {
    formattedTime = dayjs(date).format("YYYY年M月D日HH:mm:ss");
  }
  return formattedTime;
};
