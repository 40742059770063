<template>
  <div class="message-list">
    <a-card
      size="small"
      :body-style="{
        padding: '0px',
        minHeight: '410px',
        background: '#F5F5F5',
      }"
      :head-style="{
        background: '#F4F8FA',
      }"
    >
      <h5 style="margin-left: 10px">消息列表</h5>
      <div
        v-for="user in userList"
        :class="'message-list-item' + (user?.active == true ? ' active' : '')"
        :key="user?.id"
        @click="chooseUser(user)"
      >
        <a-row style="width: 100%">
          <a-col :xs="3" :xl="5">
            <div class="message-list-item-avatar">
              <UserInfoPopover :user="user" /><span
                style="position: relative; left: 31px; bottom: 17px"
                v-if="user?.online == true"
                ><MyIcon type="icon-geekonline"
              /></span>
            </div>
          </a-col>
          <a-col :xs="18" :xl="16">
            <div class="message-list-item-info">
              <div class="message-list-item-username">
                {{ user.userName }}
              </div>
              <div class="message-list-item-msg">
                {{ user?.lastMsg }}
              </div>
            </div>
          </a-col>
          <a-col :xs="3" :xl="3">
            <div style="margin-top: 20px">
              <a-badge
                v-if="user?.unreadNum > 0"
                :count="user?.unreadNum"
                :numberStyle="{ fontSize: '11px' }"
              />
            </div>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import UserInfoPopover from "@/components/UserInfoPopover.vue";
import MyIcon from "@/components/MyIcon.vue";
import { MsgControllerService } from "../../../../generated";
import { message } from "ant-design-vue";
const cntUser = ref({});
const emit = defineEmits(["chooseUser"]);
const chooseUser = (user: any) => {
  cntUser.value["active"] = false;
  cntUser.value = user;
  cntUser.value["active"] = true;
  if (cntUser.value["unreadNum"] > 0) {
    readMsg();
    cntUser.value["unreadNum"] = 0;
  }
  console.info("当前选择" + user.userName);
  emit("chooseUser", user);
};
const readMsg = () => {
  MsgControllerService.readMessageUsingPost(cntUser.value.id)
    .then((res) => {
      if (res.code == 0) {
        console.log("读消息");
      } else {
        message.warning(res.message);
      }
    })
    .catch((error) => {
      message.error(error.message);
    });
};
const props = defineProps({
  userList: {
    type: Array,
    required: true,
  },
});
</script>

<style scoped lang="less">
.message-list {
  background: #f5f5f5;
  overflow-y: auto;
  height: 480px;
  .message-list-item {
    height: 50px;
    /*background: red;*/
    display: flex;
    width: 100%;
    cursor: pointer;
    .message-list-item-avatar {
      margin-left: 3px;
      padding: 3px;
      height: 100%;
    }

    .message-list-item-info {
      margin-left: 5px;

      .message-list-item-username {
        height: 28px;
        line-height: 28px;
        font-size: 12px;
      }

      .message-list-item-msg {
        height: 18px;
        padding-left: 3px;
        font-size: 10px;
        color: #8a8f97;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .message-list-item:hover {
    background: rgba(216, 216, 216, 0.8);
  }

  .active {
    background: rgba(200, 198, 197, 0.7);
  }
}
.message-list::-webkit-scrollbar {
  width: 5px;
  height: 20px;
}

.message-list::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 设置滚动条背景颜色 */
}

.message-list::-webkit-scrollbar-thumb {
  background-color: #8a8f97; /* 设置滚动条滑块颜色 */
  border-radius: 4px; /* 设置滚动条滑块圆角 */
}

.message-list::-webkit-scrollbar-thumb:hover {
  background-color: slategrey; /* 设置滚动条滑块在悬停时的颜色 */
}
</style>
