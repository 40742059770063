// initial state
import { StoreOptions } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";
import { UserControllerService } from "../../generated";
import { message } from "ant-design-vue";

export default {
  namespaced: true,
  state: () => ({
    loginUser: {
      userName: "未登录",
    },
  }),
  actions: {
    async getLoginUser({ commit, state }, payload) {
      const res = await UserControllerService.getLoginUserUsingGet().then(
        (res) => {
          console.log("获取资料", res);
          if (res.code == 0) {
            console.log("获取用户资料", res);
            commit("updateUser", res.data);
          } else {
            if (res.code == 40102) {
              message.error("token失效");
            }
            localStorage.removeItem("token");
            commit("updateUser", {
              ...state.loginUser,
              userRole: ACCESS_ENUM.NOT_LOGIN,
            });
          }
        }
      );
    },
  },
  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload;
    },
  },
} as StoreOptions<any>;
