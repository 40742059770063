/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_HotEventsVO_ } from '../models/BaseResponse_HotEventsVO_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_Page_HotEvents_ } from '../models/BaseResponse_Page_HotEvents_';
import type { BaseResponse_Page_HotEventsVO_ } from '../models/BaseResponse_Page_HotEventsVO_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { HotEventsAddRequest } from '../models/HotEventsAddRequest';
import type { HotEventsEditRequest } from '../models/HotEventsEditRequest';
import type { HotEventsQueryRequest } from '../models/HotEventsQueryRequest';
import type { HotEventsUpdateRequest } from '../models/HotEventsUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HotEventsControllerService {
    /**
     * addHotEvents
     * @param hotEventsAddRequest hotEventsAddRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addHotEventsUsingPost(
        hotEventsAddRequest: HotEventsAddRequest,
    ): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hot_events/add',
            body: hotEventsAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * deleteHotEvents
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteHotEventsUsingPost(
        deleteRequest: DeleteRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hot_events/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * editHotEvents
     * @param hotEventsEditRequest hotEventsEditRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static editHotEventsUsingPost(
        hotEventsEditRequest: HotEventsEditRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hot_events/edit',
            body: hotEventsEditRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * getHotEventsVOById
     * @param id id
     * @returns BaseResponse_HotEventsVO_ OK
     * @throws ApiError
     */
    public static getHotEventsVoByIdUsingGet(
        id?: number,
    ): CancelablePromise<BaseResponse_HotEventsVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hot_events/get/vo',
            query: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listHotEventsByPage
     * @param hotEventsQueryRequest hotEventsQueryRequest
     * @returns BaseResponse_Page_HotEvents_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listHotEventsByPageUsingPost(
        hotEventsQueryRequest: HotEventsQueryRequest,
    ): CancelablePromise<BaseResponse_Page_HotEvents_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hot_events/list/page',
            body: hotEventsQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listHotEventsVOByPage
     * @param hotEventsQueryRequest hotEventsQueryRequest
     * @returns BaseResponse_Page_HotEventsVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listHotEventsVoByPageUsingPost(
        hotEventsQueryRequest: HotEventsQueryRequest,
    ): CancelablePromise<BaseResponse_Page_HotEventsVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hot_events/list/page/vo',
            body: hotEventsQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * updateHotEvents
     * @param hotEventsUpdateRequest hotEventsUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateHotEventsUsingPost(
        hotEventsUpdateRequest: HotEventsUpdateRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hot_events/update',
            body: hotEventsUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
