/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_Page_Resources_ } from '../models/BaseResponse_Page_Resources_';
import type { BaseResponse_Page_ResourcesVO_ } from '../models/BaseResponse_Page_ResourcesVO_';
import type { BaseResponse_ResourcesVO_ } from '../models/BaseResponse_ResourcesVO_';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { ResourcesAddRequest } from '../models/ResourcesAddRequest';
import type { ResourcesEditRequest } from '../models/ResourcesEditRequest';
import type { ResourcesQueryRequest } from '../models/ResourcesQueryRequest';
import type { ResourcesUpdateRequest } from '../models/ResourcesUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ResourcesControllerService {
    /**
     * addResources
     * @param resourcesAddRequest resourcesAddRequest
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static addResourcesUsingPost(
        resourcesAddRequest: ResourcesAddRequest,
    ): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/add',
            body: resourcesAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * deleteResources
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteResourcesUsingPost(
        deleteRequest: DeleteRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * editResources
     * @param resourcesEditRequest resourcesEditRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static editResourcesUsingPost(
        resourcesEditRequest: ResourcesEditRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/edit',
            body: resourcesEditRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * getResourcesVOById
     * @param id id
     * @returns BaseResponse_ResourcesVO_ OK
     * @throws ApiError
     */
    public static getResourcesVoByIdUsingGet(
        id?: number,
    ): CancelablePromise<BaseResponse_ResourcesVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resources/get/vo',
            query: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listResourcesByPage
     * @param resourcesQueryRequest resourcesQueryRequest
     * @returns BaseResponse_Page_Resources_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listResourcesByPageUsingPost(
        resourcesQueryRequest: ResourcesQueryRequest,
    ): CancelablePromise<BaseResponse_Page_Resources_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/list/page',
            body: resourcesQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listResourcesVOByPage
     * @param resourcesQueryRequest resourcesQueryRequest
     * @returns BaseResponse_Page_ResourcesVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listResourcesVoByPageUsingPost(
        resourcesQueryRequest: ResourcesQueryRequest,
    ): CancelablePromise<BaseResponse_Page_ResourcesVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/list/page/vo',
            body: resourcesQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listMyResourcesVOByPage
     * @param resourcesQueryRequest resourcesQueryRequest
     * @returns BaseResponse_Page_ResourcesVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listMyResourcesVoByPageUsingPost(
        resourcesQueryRequest: ResourcesQueryRequest,
    ): CancelablePromise<BaseResponse_Page_ResourcesVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/my/list/page/vo',
            body: resourcesQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * searchResourcesVOByPage
     * @param resourcesQueryRequest resourcesQueryRequest
     * @returns BaseResponse_Page_ResourcesVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static searchResourcesVoByPageUsingPost(
        resourcesQueryRequest: ResourcesQueryRequest,
    ): CancelablePromise<BaseResponse_Page_ResourcesVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/search/page/vo',
            body: resourcesQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * updateResources
     * @param resourcesUpdateRequest resourcesUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateResourcesUsingPost(
        resourcesUpdateRequest: ResourcesUpdateRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/resources/update',
            body: resourcesUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
