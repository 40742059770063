<template>
  <div class="header">
    <a-row style="height: 100%; width: 100%">
      <a-col :xs="7" :lg="11">
        <a-row>
          <a-col :xs="24" :xl="4">
            <div class="logo" style="width: 100%; height: 100%">
              <a-row style="width: 100%; height: 100%">
                <a-col :xs="12" :lg="15">
                  <img src="@/assets/images/logo.png"
                /></a-col>
                <a-col :xs="0" :lg="9">
                  <span
                    style="font-size: 17px; cursor: pointer"
                    @click="toPage('/')"
                    >极客</span
                  >
                </a-col>
                <a-col :xs="12" :lg="0">
                  <a-button
                    type="primary"
                    style="margin-bottom: 16px"
                    @click="showDrawer"
                  >
                    <MenuUnfoldOutlined v-if="!open" />
                    <MenuFoldOutlined v-else />
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col v-if="isXlSize" :lg="20">
            <a-row>
              <div class="menu-left">
                <a-menu
                  v-model:selectedKeys="current"
                  mode="horizontal"
                  :items="items"
                  @click="menuClick"
                />
              </div>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="13" :lg="6">
        <a-row>
          <a-col :xs="1" :lg="2"></a-col>
          <a-col :xs="20" :lg="18">
            <div class="search" v-if="searchVisible">
              <a-input-search
                v-model:value="value"
                placeholder="搜点啥呢~"
                enter-button
                @search="onSearch"
              />
            </div>
          </a-col>
          <a-col :xs="1" :lg="2"></a-col>
        </a-row>
      </a-col>
      <a-col :xs="4" :lg="0">
        <a-botton>
          <a-popover placement="bottom">
            <template #content>
              <MyInfoCard :loginUser="loginUser" />
            </template>
            <a-avatar
              class="avatar"
              v-if="loginUser?.id"
              shape="square"
              :size="40"
              style="background-color: #079edc; border: none"
            >
              <template #icon>
                <template v-if="!loginUser.userAvatar"
                  >{{ loginUser.userName[0] }}
                </template>
                <img v-else :src="loginUser.userAvatar"
              /></template>
            </a-avatar>
            <a class="noLogin" v-else>登录</a>
          </a-popover>
        </a-botton>
      </a-col>
      <a-col :xs="0" :lg="7">
        <div class="menu-right">
          <a-popover placement="bottom">
            <template #content>
              <MyInfoCard :loginUser="loginUser" />
            </template>
            <a-avatar
              class="avatar"
              v-if="loginUser?.id"
              shape="square"
              :size="45"
              style="background-color: #079edc; border: none"
            >
              <template #icon>
                <template v-if="!loginUser.userAvatar"
                  >{{ loginUser.userName[0] }}
                </template>
                <img v-else :src="loginUser.userAvatar"
              /></template>
            </a-avatar>
            <a class="noLogin" v-else>登录</a>
          </a-popover>
          <div class="menu-right-item">
            <a-badge :count="unReadMsgCount" title="你有新的消息">
              <MyIcon
                class="my-icon"
                type="icon-geekxiaoxi"
                @click="toPage('/message')"
              />
            </a-badge>
            消息
          </div>
          <div
            class="menu-right-item"
            @click="toPage('/user/center/' + loginUser?.id + '/collect')"
          >
            <MyIcon class="my-icon" type="icon-geekshoucang1" />
            收藏
          </div>
          <div
            class="menu-right-item"
            @click="toPage('/user/center/' + loginUser?.id + '/history')"
          >
            <MyIcon class="my-icon" type="icon-geekshouye" />
            历史
          </div>
          <div class="menu-right-item" @click="message.info('敬请期待！')">
            <MyIcon class="my-icon" type="icon-geekwj-jh" />
            计划
          </div>
          <a
            class="menu-right-item"
            style="margin-right: 20px"
            @click="toPage('/edit/add')"
          >
            <MyIcon class="my-icon" type="icon-geekfabu" />
            发布
          </a>
        </div>
      </a-col>
    </a-row>
    <a-drawer
      :width="200"
      title="Hello，Geek！"
      placement="left"
      :open="open"
      @close="onClose"
    >
      <a-menu
        id="dddddd"
        v-model:openKeys="current"
        v-model:selectedKeys="current"
        style="width: 256px"
        mode="inline"
        :items="items"
        @click="menuClick"
      ></a-menu>
    </a-drawer>
  </div>
</template>

<script setup lang="ts">
import MyIcon from "@/components/MyIcon.vue";
import MyInfoCard from "@/components/MyInfoCard.vue";
import {
  h,
  ref,
  onMounted,
  defineProps,
  watch,
  onBeforeUnmount,
  computed,
} from "vue";
import {
  HomeOutlined,
  AppstoreOutlined,
  SmileOutlined,
  BulbOutlined,
  TrophyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons-vue";
import { MenuProps, message } from "ant-design-vue";
import store from "@/store";
import { useRouter } from "vue-router";
import type { DrawerProps } from "ant-design-vue";
import { MsgControllerService } from "../../generated";
import { debounce, throttle } from "lodash";

const placement = ref<DrawerProps["placement"]>("left");
const open = ref<boolean>(false);

const showDrawer = () => {
  open.value = true;
};
const screenWidth = window.innerWidth;

//解决当xs转xl时，导航消失的问题
const isXlSize = ref(true);
const handleResize = () => {
  const isxl = window.matchMedia("(min-width: 1200px)").matches;
  isXlSize.value = isxl;
};
onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
const onClose = () => {
  open.value = false;
};
const props = defineProps({
  loginUser: {
    type: Object, // 根据实际情况指定类型
    required: true, // 根据实际情况指定是否为必需
  },
});

const value = ref<string>("");

const current = ref<string[]>(["/"]);

const items = ref<MenuProps["items"]>([
  {
    key: "/",
    icon: () => h(HomeOutlined),
    label: "首页",
    title: "首页",
  },
  {
    key: "/resources/list",
    icon: () => h(AppstoreOutlined),
    label: "资源",
    title: "资源",
  },
  {
    key: "/community",
    icon: () => h(SmileOutlined),
    label: "社区",
    title: "社区",
    children: [
      {
        label: "社区中心",
        key: "/community/center",
      },
      {
        label: "创建社区",
        key: "/community/create",
      },
      // {
      //   label: "我的社区",
      //   key: "/community/my",
      // },
    ],
  },
  {
    key: "/ranking",
    icon: () => h(TrophyOutlined),
    label: "排行榜",
    title: "排行榜",
  },
  {
    key: "/events",
    icon: () => h(BulbOutlined),
    label: "活动赛事",
    title: "活动赛事",
  },
]);

const menuClick = ({ key }: any) => {
  console.log(key);
  router.push(key);
};

const toPage = (path: string) => {
  if (path == "/message") {
    unReadMsgCount.value = 0;
  }
  if (loginUser.value?.id == null) {
    message.warning("请先登录");
    return router.push("/user/login");
  }
  router.push(path);
};

const onSearch = debounce((searchValue: string) => {
  const fn = (searchValue: string) => {
    router.push("/search?text=" + searchValue);
  };
  fn(searchValue);
}, 500);

// 获取路由实例
const router = useRouter();
const searchVisible = ref(true);
const unReadMsgCount = ref(0);
// 导航守卫，监听页面路由变化
watch(
  () => router.currentRoute.value.path,
  (path) => {
    // 根据路由路径设置当前选中的菜单项
    current.value = [path];
    if (path.includes("search")) {
      searchVisible.value = false;
    } else {
      searchVisible.value = true;
    }
  }
);
const loginUser = ref({});
const userList = ref([]);
const ws = ref();
onMounted(async () => {
  handleResize();
  window.addEventListener("resize", handleResize);
  setTimeout(async () => {
    loginUser.value = store.state.user.loginUser;
    // store.dispatch("userList/getUserList");
    // 登录后建立连接
    if (loginUser.value?.id) {
      MsgControllerService.getMyUnReadMsgCountUsingPost().then((res) => {
        if (res.code == 0) {
          if (typeof res.data === "string") {
            unReadMsgCount.value = Number(res.data);
          } else {
            unReadMsgCount.value = res.data;
          }
        } else {
          message.warning(res.message);
        }
      });

      const websocket = store.state.websocket.websocket;
      if (!websocket) {
        await store.dispatch("websocket/createConnect");
        ws.value = store.state.websocket.websocket;
        if (ws.value) {
          ws.value.onmessage = (e: any) => {
            userList.value = store.state.userList.userList;
            const newMsg = JSON.parse(e.data);
            if (newMsg.code == 200) {
              console.log("收到了消息", newMsg);
              if (newMsg.data.onlineUserList[0]) {
                const ids = newMsg.data.onlineUserList;
                userList.value.forEach((user) => {
                  if (ids.includes(user.id)) {
                    user["online"] = true;
                  } else {
                    user["online"] = false;
                  }
                });
                store.state.userList.userList = userList.value;
              }
              if (newMsg.data.msg) {
                unReadMsgCount.value = unReadMsgCount.value + 1;
                console.info("新的消息", newMsg);
              }
            }
            // msgData.value.push(newMsg);
          };
        } else {
          message.info("服务连接失败");
        }
      } else {
        // await store.dispatch("userList/getUserList");
        ws.value = websocket;
        ws.value.onmessage = (e: any) => {
          const newMsg = JSON.parse(e.data);
          console.log(newMsg);
          if (newMsg.code == 201) {
            console.log("系统消息");
          } else if (newMsg.code == 200) {
            console.log("私信");
          }
        };
      }
    } else {
      await store.dispatch("user/getLoginUser");
      loginUser.value = store.state.user.loginUser;
      if (loginUser.value?.id) {
        MsgControllerService.getMyUnReadMsgCountUsingPost().then((res) => {
          if (res.code == 0) {
            if (typeof res.data === "string") {
              unReadMsgCount.value = Number(res.data);
            } else {
              unReadMsgCount.value = res.data;
            }
          } else {
            message.warning(res.message);
          }
        });
      }
    }
  }, 200);
});
</script>

<style scoped lang="less">
.header {
  background: white;
  box-shadow: 0px 2px 2px rgba(228, 230, 235, 0.5);
  display: flex;
  height: 65px;

  .logo {
    float: left;
    font-size: 100%;
    font-family: 幼圆;
    font-weight: bold;
    color: rgba(4, 122, 194, 1);
    text-align: center;
    vertical-align: center;

    img {
      width: 80%;
      height: 80%;
    }
  }

  .search {
    width: 100%;
    height: 65px;
    padding: 15px;
  }

  .menu-right {
    float: right;
    height: 63px;
    display: flex;

    .menu-right-item {
      padding: 13px;
      width: 50px;
      height: 50px;
      font-size: 12px;
      color: rgba(122, 121, 121, 1);
      display: grid;
      text-align: center;
      line-height: 10px;

      .my-icon {
        text-align: center;
        font-size: 28px !important;
        margin-bottom: 3px;
      }
    }

    .avatar {
      width: 45px;
      height: 45px;
      margin-top: 12px;
      margin-right: 10px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .avatar:hover {
      cursor: pointer;
    }
  }
}

.noLogin {
  border-radius: 50%;
  margin-top: 13px;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  background: #1677ff;
  color: white;
  text-align: center;
  line-height: 40px;
}
</style>
