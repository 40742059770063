import { StoreOptions } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";
import { creatWebSocket } from "@/service/websocket/websocket";

export default {
  namespaced: true,
  state: () => ({
    websocket: null,
  }),
  actions: {
    async createConnect({ commit, rootState }) {
      const loginUser = rootState.user.loginUser;
      if (loginUser?.id) {
        const ws = await creatWebSocket(loginUser.id);
        commit("updateWebSocket", ws);
      }
    },
  },
  mutations: {
    updateWebSocket(state, payload) {
      state.websocket = payload;
    },
  },
} as StoreOptions<any>;
