import { createStore } from "vuex";

import user from "./user";
import tag from "./tag";
import websocket from "./websocket";
import userList from "./userList";

export default createStore({
  mutations: {},
  actions: {},
  modules: {
    user,
    tag,
    websocket,
    userList,
  },
});
