<template>
  <div class="home">
    <a-spin tip="加载中..." :spinning="loading">
      <a-row>
        <a-col :xs="{ span: 24, order: 2 }" :lg="{ span: 15, order: 1 }">
          <a-row justify="right">
            <a-col :xs="24" :lg="8"></a-col>
            <a-col :xs="24" :lg="16">
              <!--            <a-skeleton active :loading="loading">-->
              <a-row>
                <a-col :xs="14">
                  <div
                    v-if="!loading"
                    style="
                      height: 85%;
                      padding: 5px;
                      padding-top: 15px;
                      cursor: pointer;
                      z-index: 5;
                    "
                  >
                    <img
                      onerror="this.src='https://xmj-1307541481.cos.ap-shanghai.myqcloud.com/geek/post_image/1764565515746938882/aPsbikqJ-img-error.png';this.onerror=null"
                      @click="toPage('/hot/details/' + hotList[0]?.id)"
                      width="100%"
                      height="100%"
                      :src="hotList[0]?.cover"
                    />
                    <div class="home-hot-item-title" style="font-size: 100%">
                      {{ hotList[0]?.title }}
                    </div>
                  </div>
                </a-col>

                <a-col :xs="10">
                  <a-row>
                    <a-col :xs="12">
                      <div
                        v-if="!loading"
                        style="
                          cursor: pointer;
                          width: 100%;
                          height: 85%;
                          padding: 5px;
                          padding-top: 20px;
                          z-index: 5;
                        "
                      >
                        <img
                          onerror="this.src='https://xmj-1307541481.cos.ap-shanghai.myqcloud.com/geek/post_image/1764565515746938882/aPsbikqJ-img-error.png';this.onerror=null"
                          height="100%"
                          @click="toPage('/hot/details/' + hotList[1]?.id)"
                          width="100%"
                          :src="hotList[1]?.cover"
                        />
                        <div class="home-hot-item-title">
                          {{ hotList[1]?.title }}
                        </div>
                      </div>
                    </a-col>
                    <a-col :xs="12">
                      <div
                        v-if="!loading"
                        style="
                          width: 100%;
                          cursor: pointer;
                          height: 85%;
                          padding: 5px;
                          padding-top: 20px;
                          z-index: 5;
                        "
                      >
                        <img
                          onerror="this.src='https://xmj-1307541481.cos.ap-shanghai.myqcloud.com/geek/post_image/1764565515746938882/aPsbikqJ-img-error.png';this.onerror=null"
                          height="100%"
                          width="100%"
                          @click="toPage('/hot/details/' + hotList[2]?.id)"
                          :src="hotList[2]?.cover"
                        />
                        <div class="home-hot-item-title">
                          {{ hotList[2]?.title }}
                        </div>
                      </div>
                    </a-col>
                    <a-col :xs="12">
                      <div
                        v-if="!loading"
                        style="
                          width: 100%;
                          height: 85%;
                          cursor: pointer;
                          padding: 5px;
                          z-index: 5;
                        "
                      >
                        <img
                          onerror="this.src='https://xmj-1307541481.cos.ap-shanghai.myqcloud.com/geek/post_image/1764565515746938882/aPsbikqJ-img-error.png';this.onerror=null"
                          height="100%"
                          width="100%"
                          @click="toPage('/hot/details/' + hotList[3]?.id)"
                          :src="hotList[3]?.cover"
                        />
                        <div class="home-hot-item-title">
                          {{ hotList[3]?.title }}
                        </div>
                      </div>
                    </a-col>
                    <a-col :xs="12">
                      <div
                        v-if="!loading"
                        style="
                          width: 100%;
                          height: 85%;
                          cursor: pointer;
                          padding: 5px;
                          z-index: 5;
                        "
                      >
                        <img
                          onerror="this.src='https://xmj-1307541481.cos.ap-shanghai.myqcloud.com/geek/post_image/1764565515746938882/aPsbikqJ-img-error.png';this.onerror=null"
                          height="100%"
                          width="100%"
                          @click="toPage('/hot/details/' + hotList[4]?.id)"
                          :src="hotList[4]?.cover"
                        />
                        <div class="home-hot-item-title">
                          {{ hotList[4]?.title }}！
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>

                <a-col :xs="24">
                  <div style="height: 70px"></div>
                </a-col>
              </a-row>
              <!--            </a-skeleton>-->

              <div
                style="
                  background: white;
                  margin-top: -15%;
                  padding-bottom: 30px;
                "
              >
                <a-tabs
                  v-model:activeKey="activeKey"
                  :tab-position="mode"
                  :style="{
                    background: 'white',
                    marginLeft: '20px',
                    boxShadow: 'none',
                    border: 'none',
                    textAlign: 'left',
                  }"
                  @tabScroll="callback"
                  @tabClick="tabClick"
                >
                  <a-tab-pane
                    v-for="item in menuItems"
                    :key="item.name"
                    :tab="item.name"
                  >
                  </a-tab-pane>
                </a-tabs>
                <template v-if="!cntArticles[0]">
                  <a-empty
                    description="还没有这类文章，看看其他的吧"
                    :image="simpleImage"
                  />
                </template>
                <a-card
                  :loading="postLoading"
                  class="home-article"
                  v-for="item in cntArticles"
                  :key="item.id"
                >
                  <a-row>
                    <a-col :xs="24" :lg="17">
                      <div class="home-article-info">
                        <div class="home-article-info-top">
                          <div>
                            <UserInfoPopover :user="item.user" />
                          </div>
                          <a
                            @click="
                              toPage('/user/center/' + item.user.id + '/info')
                            "
                            >{{ item.user.userName }}</a
                          >
                        </div>
                        <h4
                          style="
                            font-weight: bold;
                            margin-top: 5px;
                            margin-bottom: 5px;
                            cursor: pointer;
                          "
                          @click="toPage('/article/' + item.id)"
                        >
                          <template v-if="item.title"
                            >《{{ item.title }}》
                          </template>
                        </h4>
                        <p
                          @click="toPage('/article/' + item.id)"
                          style="font-size: 12px; color: grey; cursor: pointer"
                        >
                          {{ item.brief }}
                        </p>
                      </div>
                      <span
                        style="font-size: 10px"
                        v-for="{ icon, text } in [
                          { icon: EyeOutlined, text: item.readingCount },
                          { icon: LikeOutlined, text: item.thumbNum },
                          { icon: StarOutlined, text: item.favourNum },
                          {
                            icon: EditOutlined,
                            text: formatDate(item.createTime),
                          },
                        ]"
                        :key="icon"
                      >
                        <component :is="icon" style="margin-right: 5px" />
                        <span style="margin-right: 10px">{{ text }}</span>
                      </span>
                    </a-col>
                    <a-col :xs="24" :lg="7">
                      <div
                        class="home-article-img"
                        v-if="
                          item?.imgUrl &&
                          item?.imgUrl != '' &&
                          item?.imgUrl != []
                        "
                      >
                        <a-image
                          onerror="this.src='https://xmj-1307541481.cos.ap-shanghai.myqcloud.com/geek/post_image/1764565515746938882/aPsbikqJ-img-error.png';this.onerror=null"
                          style="border-radius: 5px"
                          width="160px"
                          height="90px"
                          :src="item.imgUrl"
                          :alt="item.title"
                        />
                      </div>
                    </a-col>
                  </a-row>
                </a-card>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="{ span: 24, order: 1 }" :lg="{ span: 8, order: 2 }">
          <a-row>
            <a-col :xs="24" :lg="5"></a-col>
            <a-col :xs="24" :lg="14">
              <div style="margin-top: 10px">
                <a-card size="small" title="热点">
                  <template #extra
                    ><span class="hot-events-item" @click="toPage('/events')"
                      >更多</span
                    ></template
                  >
                  <span v-for="item in hotList" :key="item?.id">
                    <p
                      @click="toPage('/hot/details/' + item.id)"
                      class="hot-events-item"
                    >
                      {{ item?.title }}
                    </p>
                  </span>
                </a-card>
              </div>
              <div style="margin-top: 30px" v-if="userList[0]">
                <a-card size="small" title="用户推荐" v-if="true">
                  <!--                  <template #extra-->
                  <!--                    ><span class="hot-events-item">更多</span></template-->
                  <!--                  >-->
                  <template v-for="user in userList" :key="user.id">
                    <p class="hot-events-item">
                      <UserInfoPopover :user="user" />
                      {{ user?.userName }}
                      <a-tooltip placement="bottom" v-if="user?.userSex">
                        <template #title>
                          <span v-if="user?.userSex == 0">性别：女</span>
                          <span v-else-if="user?.userSex == 1">性别：男</span>
                          <span v-else>未知</span>
                        </template>
                        <MyIcon type="icon-geeknan" />
                      </a-tooltip>
                      <a-tooltip placement="bottom" v-if="user?.fullName">
                        <template #title>
                          <span>已完成实名认证</span>
                        </template>
                        <MyIcon type="icon-geeklanVrenzheng" />
                      </a-tooltip>
                      <a-tooltip placement="bottom" v-if="user?.userSchool">
                        <template #title>
                          <span>校园认证</span>
                        </template>
                        <MyIcon type="icon-geekzhuanjia" />
                      </a-tooltip>
                      <a-tooltip
                        placement="bottom"
                        v-if="user?.userRole == 'admin'"
                      >
                        <template #title>
                          <span>管理员</span>
                        </template>
                        <MyIcon type="icon-geekmianxingwenjianguanliyuan" />
                      </a-tooltip>
                    </p>
                  </template>
                </a-card>
              </div>
            </a-col>
            <a-col :xs="24" :lg="5"></a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-spin>
  </div>
  <!--  <MoveWindow />-->
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import type { TabsProps } from "ant-design-vue";
import { message } from "ant-design-vue";
import MyIcon from "@/components/MyIcon.vue";
import {
  HotEventsControllerService,
  OpenAPI,
  PostControllerService,
  UserControllerService,
} from "../../../generated";
import router from "@/router";
import {
  StarOutlined,
  LikeOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import { formatDate } from "@/utils/myTimeUtils";
import { useStore } from "vuex";
import UserInfoPopover from "@/components/UserInfoPopover.vue";
import { debounce, throttle } from "lodash";
import { useRoute } from "vue-router";
// import MoveWindow from "@/views/message/MoveWindow.vue";

const loading = ref(true);
const toPage = (path: string) => {
  router.push(path);
};
const pagination = {
  onChange: (page: number) => {
    console.log(page);
  },
  pageSize: 3,
};

const mode = ref<TabsProps["tabPosition"]>("top");
const activeKey = ref("推荐");
const callback: TabsProps["onTabScroll"] = (val) => {
  // console.log(val);
};

const tagList = ref([
  {
    name: "推荐",
  },
]);
const postListQuery = ref({
  current: 1,
  pageSize: 20,
  communityId: 1,
  tags: "",
  sortOrder: "descned",
  sortField: "createTime",
});
const articles = ref([]);
const cntArticles = ref([]);
const store = useStore();
const getCommentPostList = () => {
  postLoading.value = true;
  PostControllerService.recommendListPostVoByPageUsingPost({
    current: 1,
    pageSize: 20,
    communityId: 1,
    sortOrder: "descned",
    sortField: "createTime",
  })
    .then((res) => {
      if (res.code == 0) {
        articles.value = res.data.records;
        cntArticles.value = articles.value;
        // tabClick();
      } else {
        message.warning(res.message);
      }
    })
    .finally(() => {
      postLoading.value = false;
    });
};
const lastClick = ref("推荐");
const tabClick = () => {
  postLoading.value = true;
  setTimeout(() => {
    if (lastClick.value == activeKey.value) {
      postLoading.value = false;
      return;
    }
    lastClick.value = activeKey.value;
    if (activeKey.value == "推荐") {
      return getCommentPostList();
    }
    console.log("点击了：", activeKey.value);
    postListQuery.value.tags = [activeKey.value];
    getPostList();
  }, 100);
};
const hotList = ref([]);
const userList = ref([]);

const getPostList = debounce(() => {
  const fn = () => {
    postLoading.value = true;
    if (postListQuery.value.tags == "") {
      postListQuery.value.tags = null;
    }
    PostControllerService.listPostVoByPageUsingPost(postListQuery.value)
      .then((res) => {
        // console.log("获取文章：", res);
        if (res.code == 0) {
          articles.value = res.data.records;
          cntArticles.value = articles.value;
        } else {
          message.warning(res.message);
        }
      })
      .finally(() => {
        postLoading.value = false;
      });
  };
  fn();
}, 1000);
const userTags = ref([]);
const loginUser = ref();
const menuItems = ref([]);
const postLoading = ref(true);
const route = useRoute();
onMounted(async () => {
  const tags = store.state.tag.tagList;
  const token = route.query.token;
  if (token != null) {
    localStorage.setItem("token", token);
    OpenAPI.TOKEN = token;
    router.push("/");
    setTimeout(() => {
      if (store.state?.user?.loginUser?.id == null) {
        location.reload();
      }
    }, 500);
  }
  tags.forEach((item: any) => {
    if (item.type == "兴趣") {
      tagList.value = [{ name: "推荐" }, ...item.tagList];
    }
  });
  loginUser.value = store.state.user.loginUser;
  if (loginUser.value != null) {
    if (loginUser.value.tags && loginUser.value.tags.length > 3) {
      userTags.value = JSON.parse(loginUser.value.tags);
      if (userTags.value[0]) {
        let i = 2;
        menuItems.value = [
          {
            id: 1,
            name: "推荐",
          },
        ];
        userTags.value.forEach((tag) => {
          menuItems.value.push({
            id: i++,
            name: tag,
          });
        });
      }
    } else {
      menuItems.value = [...tagList.value];
    }
  }

  HotEventsControllerService.listHotEventsVoByPageUsingPost({
    current: 1,
    pageSize: 5,
    communityId: 1,
  })
    .then((res) => {
      if (res.code == 0) {
        hotList.value = res.data.records;
      } else {
        message.warning(res.message);
      }
    })
    .then(() => {
      //获取文章列表
      getCommentPostList();
    })
    .then(() => {
      UserControllerService.recommendUsersUsingPost({
        current: 1,
        pageSize: 10,
      })
        .then((res) => {
          console.log("获取推荐", res);
          if (res.code == 0) {
            console.log("获取到推荐用户列表", res);
            userList.value = res.data.records;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    })
    .catch((error) => {
      message.error(error.message);
    })
    .finally(() => {
      setTimeout(() => {
        if (loginUser.value?.id == null) {
          menuItems.value = [...tagList.value];
        }
      }, 200);
    });
});
</script>

<style scoped lang="less">
.home {
  margin-top: 20px;
}

.home-article {
  .home-article-info {
    width: 100%;
    background: white;

    .home-article-info-top {
      width: 100%;
      display: flex;

      a {
        color: grey;
        margin: 5px;
      }

      a:hover {
        color: #2f80ed;
      }
    }
  }

  .home-article-img {
    z-index: 5;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.home-hot-item-title {
  position: relative;
  bottom: 17%;
  width: 100%;
  height: 18%;
  padding: 2%;
  font-size: 70%;
  vertical-align: middle;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: linear-gradient(
    90deg,
    rgba(52, 134, 227, 1) 0%,
    rgba(0, 119, 255, 0) 100%
  );
}

.hot-events-item {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #7a858f;
  font-size: 12px;
}

.hot-events-item:hover {
  color: #2f80ed;
}
</style>
