/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_int_ } from '../models/BaseResponse_int_';
import type { BaseResponse_Page_CommunityMember_ } from '../models/BaseResponse_Page_CommunityMember_';
import type { BaseResponse_Page_CommunityMemberVO_ } from '../models/BaseResponse_Page_CommunityMemberVO_';
import type { CommunityMemberAddRequest } from '../models/CommunityMemberAddRequest';
import type { CommunityMemberEditRequest } from '../models/CommunityMemberEditRequest';
import type { CommunityMemberQueryRequest } from '../models/CommunityMemberQueryRequest';
import type { CommunityMemberUpdateRequest } from '../models/CommunityMemberUpdateRequest';
import type { DeleteRequest } from '../models/DeleteRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommunityMemberControllerService {
    /**
     * deleteCommunityMember
     * @param deleteRequest deleteRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteCommunityMemberUsingPost(
        deleteRequest: DeleteRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/communityMember/delete',
            body: deleteRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * joinCommunity
     * @param communityMemberAddRequest communityMemberAddRequest
     * @returns BaseResponse_int_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static joinCommunityUsingPost(
        communityMemberAddRequest: CommunityMemberAddRequest,
    ): CancelablePromise<BaseResponse_int_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/communityMember/join',
            body: communityMemberAddRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listCommunityMemberByPage
     * @param communityMemberQueryRequest communityMemberQueryRequest
     * @returns BaseResponse_Page_CommunityMember_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listCommunityMemberByPageUsingPost(
        communityMemberQueryRequest: CommunityMemberQueryRequest,
    ): CancelablePromise<BaseResponse_Page_CommunityMember_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/communityMember/list/page',
            body: communityMemberQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listCommunityMemberVOByPage
     * @param communityMemberQueryRequest communityMemberQueryRequest
     * @returns BaseResponse_Page_CommunityMemberVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listCommunityMemberVoByPageUsingPost(
        communityMemberQueryRequest: CommunityMemberQueryRequest,
    ): CancelablePromise<BaseResponse_Page_CommunityMemberVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/communityMember/list/page/vo',
            body: communityMemberQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * listMyCommunityMemberVOByPage
     * @param communityMemberQueryRequest communityMemberQueryRequest
     * @returns BaseResponse_Page_CommunityMemberVO_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static listMyCommunityMemberVoByPageUsingPost(
        communityMemberQueryRequest: CommunityMemberQueryRequest,
    ): CancelablePromise<BaseResponse_Page_CommunityMemberVO_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/communityMember/my/list/page/vo',
            body: communityMemberQueryRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * editCommunityMember
     * @param communityMemberEditRequest communityMemberEditRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static editCommunityMemberUsingPost(
        communityMemberEditRequest: CommunityMemberEditRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/communityMember/quit',
            body: communityMemberEditRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * updateCommunityMember
     * @param communityMemberUpdateRequest communityMemberUpdateRequest
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static updateCommunityMemberUsingPost(
        communityMemberUpdateRequest: CommunityMemberUpdateRequest,
    ): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/communityMember/update',
            body: communityMemberUpdateRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
