<template>
  <a-popover placement="bottom">
    <template #content>
      <div style="width: 280px"><UserCard :user="user" /></div>
    </template>
    <!--    <template #title>-->
    <!--      <span>Title</span>-->
    <!--    </template>-->
    <a-avatar
      shape="square"
      :size="40"
      style="background-color: #079edc; border: none; cursor: pointer"
    >
      <template #icon>
        <template v-if="user?.userAvatar == null || user?.userAvatar == ''"
          >{{ user?.userName[0] }}
        </template>
        <img v-else :src="user?.userAvatar"
      /></template>
    </a-avatar>
  </a-popover>
</template>
<script setup lang="ts">
import { defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import UserCard from "@/components/UserCard.vue";
const router = useRouter();
const toPage = (path: string) => {
  router.push(path);
};
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
});
</script>

<style scoped></style>
