const emojiData = [
  {
    name: "热门",
    iconArr: [
      {
        className: "bg-1f4b0",
        icon: "💰",
      },
      {
        className: "bg-1f604",
        icon: "😄",
      },
      {
        className: "bg-1f60d",
        icon: "😍",
      },
      {
        className: "bg-1f631",
        icon: "😱",
      },
      {
        className: "bg-1f602",
        icon: "😂",
      },
      {
        className: "bg-1f353",
        icon: "🍓",
      },
      {
        className: "bg-1f37a",
        icon: "🍺",
      },
      {
        className: "bg-f2615",
        icon: "☕",
      },
      {
        className: "bg-1f340",
        icon: "🍀",
      },
      {
        className: "bg-1f331",
        icon: "🌱",
      },
      {
        className: "bg-f2728",
        icon: "✨",
      },
      {
        className: "bg-f26a1",
        icon: "⚡",
      },
      {
        className: "bg-1f4ab",
        icon: "💫",
      },
      {
        className: "bg-1f4a5",
        icon: "💥",
      },
      {
        className: "bg-1f3b5",
        icon: "🎵",
      },
      {
        className: "bg-1f44f",
        icon: "👏",
      },
      {
        className: "bg-1f4aa",
        icon: "💪",
      },
      {
        className: "bg-1f44d",
        icon: "👍",
      },
      {
        className: "bg-1f449",
        icon: "👉",
      },
      {
        className: "bg-1f448",
        icon: "👈",
      },
      {
        className: "bg-1f447",
        icon: "👇",
      },
      {
        className: "bg-1f446",
        icon: "👆",
      },
      {
        className: "bg-1f44a",
        icon: "👊",
      },
      {
        className: "bg-1f48b",
        icon: "💋",
      },
      {
        className: "bg-1f48d",
        icon: "💍",
      },
      {
        className: "bg-1f451",
        icon: "👑",
      },
      {
        className: "bg-1f4a5",
        icon: "💥",
      },
      {
        className: "bg-1f495",
        icon: "💕",
      },
      {
        className: "bg-1f496",
        icon: "💖",
      },
      {
        className: "bg-1f389",
        icon: "🎉",
      },
      {
        className: "bg-1f47b",
        icon: "👻",
      },
      {
        className: "bg-f3299",
        icon: "㊙",
      },
      {
        className: "bg-1f51e",
        icon: "🔞",
      },

      {
        className: "bg-f2705",
        icon: "✅",
      },
      {
        className: "bg-f2b07",
        icon: "⬇",
      },
      {
        className: "bg-f27a1",
        icon: "➡",
      },
      {
        className: "bg-1f4af",
        icon: "💯",
      },
      {
        className: "bg-f2714",
        icon: "✔",
      },
      {
        className: "bg-f2611",
        icon: "☑",
      },
      {
        className: "bg-f2757",
        icon: "❗",
      },
      {
        className: "bg-f2753",
        icon: "❓",
      },
      {
        className: "bg-f2b55",
        icon: "⭕",
      },
      {
        className: "bg-1f680",
        icon: "🚀",
      },
      {
        className: "bg-f2708",
        icon: "✈",
      },
      {
        className: "bg-1f697",
        icon: "🚗",
      },
      {
        className: "bg-f26a0",
        icon: "⚠",
      },
      {
        className: "bg-f2693",
        icon: "⚓",
      },
      {
        className: "bg-1f4e2",
        icon: "📢",
      },
      {
        className: "bg-1f514",
        icon: "🔔",
      },
      {
        className: "bg-1f381",
        icon: "🎁",
      },
      {
        className: "bg-1f384",
        icon: "🎄",
      },
      {
        className: "bg-1f49b",
        icon: "💛",
      },
      {
        className: "bg-1f525",
        icon: "🔥",
      },
      {
        className: "bg-1f485",
        icon: "💅",
      },

      {
        className: "bg-1f64b",
        icon: "🙋",
      },
      {
        className: "bg-1f004",
        icon: "🀄",
      },
      {
        className: "bg-1f33b",
        icon: "🌻",
      },
      {
        className: "bg-1f37b",
        icon: "🍻",
      },
      {
        className: "bg-1f648",
        icon: "🙈",
      },
      {
        className: "bg-1f618",
        icon: "😘",
      },
      {
        className: "bg-f3030",
        icon: "〰",
      },
    ],
  },
  {
    name: "表情",
    iconArr: [
      {
        className: "bg-1f604",
        icon: "😄",
      },
      {
        className: "bg-1f603",
        icon: "😃",
      },
      {
        className: "bg-1f600",
        icon: "😀",
      },
      {
        className: "bg-1f60a",
        icon: "😊",
      },
      {
        className: "bg-1f609",
        icon: "😉",
      },
      {
        className: "bg-1f60d",
        icon: "😍",
      },
      {
        className: "bg-1f618",
        icon: "😘",
      },
      {
        className: "bg-1f61a",
        icon: "😚",
      },
      {
        className: "bg-1f617",
        icon: "😗",
      },
      {
        className: "bg-1f619",
        icon: "😙",
      },
      {
        className: "bg-1f61c",
        icon: "😜",
      },
      {
        className: "bg-1f61d",
        icon: "😝",
      },
      {
        className: "bg-1f61b",
        icon: "😛",
      },
      {
        className: "bg-1f633",
        icon: "😳",
      },
      {
        className: "bg-1f601",
        icon: "😁",
      },
      {
        className: "bg-1f614",
        icon: "😔",
      },
      {
        className: "bg-1f60c",
        icon: "😌",
      },
      {
        className: "bg-1f612",
        icon: "😒",
      },
      {
        className: "bg-1f61e",
        icon: "😞",
      },
      {
        className: "bg-1f623",
        icon: "😣",
      },
      {
        className: "bg-1f622",
        icon: "😢",
      },
      {
        className: "bg-1f602",
        icon: "😂",
      },
      {
        className: "bg-1f62d",
        icon: "😭",
      },
      {
        className: "bg-1f62a",
        icon: "😪",
      },
      {
        className: "bg-1f625",
        icon: "😥",
      },
      {
        className: "bg-1f630",
        icon: "😰",
      },
      {
        className: "bg-1f605",
        icon: "😅",
      },
      {
        className: "bg-1f613",
        icon: "😓",
      },
      {
        className: "bg-1f629",
        icon: "😩",
      },
      {
        className: "bg-1f62b",
        icon: "😫",
      },
      {
        className: "bg-1f628",
        icon: "😨",
      },
      {
        className: "bg-1f631",
        icon: "😱",
      },
      {
        className: "bg-1f620",
        icon: "😠",
      },
      {
        className: "bg-1f621",
        icon: "😡",
      },
      {
        className: "bg-1f624",
        icon: "😤",
      },
      {
        className: "bg-1f616",
        icon: "😖",
      },
      {
        className: "bg-1f606",
        icon: "😆",
      },
      {
        className: "bg-1f60b",
        icon: "😋",
      },
      {
        className: "bg-1f637",
        icon: "😷",
      },
      {
        className: "bg-1f60e",
        icon: "😎",
      },
      {
        className: "bg-1f634",
        icon: "😴",
      },
      {
        className: "bg-1f635",
        icon: "😵",
      },
      {
        className: "bg-1f632",
        icon: "😲",
      },
      {
        className: "bg-1f61f",
        icon: "😟",
      },
      {
        className: "bg-1f626",
        icon: "😦",
      },
      {
        className: "bg-1f627",
        icon: "😧",
      },
      {
        className: "bg-1f608",
        icon: "😈",
      },
      {
        className: "bg-1f47f",
        icon: "👿",
      },
      {
        className: "bg-1f62e",
        icon: "😮",
      },
      {
        className: "bg-1f62c",
        icon: "😬",
      },
      {
        className: "bg-1f610",
        icon: "😐",
      },
      {
        className: "bg-1f615",
        icon: "😕",
      },
      {
        className: "bg-1f62f",
        icon: "😯",
      },
      {
        className: "bg-1f636",
        icon: "😶",
      },
      {
        className: "bg-1f607",
        icon: "😇",
      },
      {
        className: "bg-1f60f",
        icon: "😏",
      },
      {
        className: "bg-1f611",
        icon: "😑",
      },
      {
        className: "bg-1f63a",
        icon: "😺",
      },
      {
        className: "bg-1f638",
        icon: "😸",
      },
      {
        className: "bg-1f63b",
        icon: "😻",
      },
      {
        className: "bg-1f63d",
        icon: "😽",
      },
      {
        className: "bg-1f63c",
        icon: "😼",
      },

      {
        className: "bg-1f640",
        icon: "🙀",
      },
      {
        className: "bg-1f63f",
        icon: "😿",
      },
      {
        className: "bg-1f639",
        icon: "😹",
      },
      {
        className: "bg-1f63e",
        icon: "😾",
      },
      {
        className: "bg-1f479",
        icon: "👹",
      },
      {
        className: "bg-1f47a",
        icon: "👺",
      },
    ],
  },
  {
    name: "动物",
    iconArr: [
      {
        className: "bg-1f436",
        icon: "🐶",
      },
      {
        className: "bg-1f43a",
        icon: "🐺",
      },
      {
        className: "bg-1f42d",
        icon: "🐱",
      },
      {
        className: "bg-1f42d",
        icon: "🐭",
      },
      {
        className: "bg-1f439",
        icon: "🐹",
      },
      {
        className: "bg-1f430",
        icon: "🐰",
      },
      {
        className: "bg-1f438",
        icon: "🐸",
      },
      {
        className: "bg-1f42f",
        icon: "🐨",
      },
      {
        className: "bg-1f43b",
        icon: "🐻",
      },
      {
        className: "bg-1f437",
        icon: "🐷",
      },
      {
        className: "bg-1f43d",
        icon: "🐽",
      },
      {
        className: "bg-1f42e",
        icon: "🐮",
      },
      {
        className: "bg-1f417",
        icon: "🐗",
      },
      {
        className: "bg-1f435",
        icon: "🐵",
      },
      {
        className: "bg-1f412",
        icon: "🐒",
      },
      {
        className: "bg-1f434",
        icon: "🐴",
      },
      {
        className: "bg-1f411",
        icon: "🐑",
      },
      {
        className: "bg-1f418",
        icon: "🐘",
      },
      {
        className: "bg-1f43c",
        icon: "🐼",
      },
      {
        className: "bg-1f427",
        icon: "🐧",
      },
      {
        className: "bg-1f426",
        icon: "🐦",
      },
      {
        className: "bg-1f424",
        icon: "🐤",
      },
      {
        className: "bg-1f425",
        icon: "🐥",
      },
      {
        className: "bg-1f423",
        icon: "🐣",
      },
      {
        className: "bg-1f414",
        icon: "🐔",
      },
      {
        className: "bg-1f40d",
        icon: "🐍",
      },
      {
        className: "bg-1f422",
        icon: "🐢",
      },
      {
        className: "bg-1f41b",
        icon: "🐛",
      },
      {
        className: "bg-1f41d",
        icon: "🐝",
      },
      {
        className: "bg-1f41c",
        icon: "🐜",
      },
      {
        className: "bg-1f41e",
        icon: "🐞",
      },
      {
        className: "bg-1f40c",
        icon: "🐌",
      },
      {
        className: "bg-1f419",
        icon: "🐙",
      },
      {
        className: "bg-1f420",
        icon: "🐠",
      },
      {
        className: "bg-1f41f",
        icon: "🐟",
      },
      {
        className: "bg-1f42c",
        icon: "🐬",
      },
      {
        className: "bg-1f433",
        icon: "🐳",
      },
      {
        className: "bg-1f40b",
        icon: "🐋",
      },
      {
        className: "bg-1f404",
        icon: "🐄",
      },
      {
        className: "bg-1f40f",
        icon: "🐏",
      },
      {
        className: "bg-1f400",
        icon: "🐀",
      },
      {
        className: "bg-1f403",
        icon: "🐃",
      },
      {
        className: "bg-1f405",
        icon: "🐅",
      },
      {
        className: "bg-1f407",
        icon: "🐇",
      },
      {
        className: "bg-1f409",
        icon: "🐉",
      },
      {
        className: "bg-1f40e",
        icon: "🐎",
      },
      {
        className: "bg-1f410",
        icon: "🐐",
      },
      {
        className: "bg-1f413",
        icon: "🐓",
      },
      {
        className: "bg-1f415",
        icon: "🐕",
      },
      {
        className: "bg-1f416",
        icon: "🐖",
      },

      {
        className: "bg-1f401",
        icon: "🐁",
      },
      {
        className: "bg-1f402",
        icon: "🐂",
      },
      {
        className: "bg-1f432",
        icon: "🐲",
      },
      {
        className: "bg-1f421",
        icon: "🐡",
      },
      {
        className: "bg-1f40a",
        icon: "🐊",
      },
      {
        className: "bg-1f42b",
        icon: "🐫",
      },
      {
        className: "bg-1f42a",
        icon: "🐪",
      },

      {
        className: "bg-1f406",
        icon: "🐆",
      },
      {
        className: "bg-1f408",
        icon: "🐈",
      },
      {
        className: "bg-1f429",
        icon: "🐩",
      },
      {
        className: "bg-1f43e",
        icon: "🐾",
      },
      {
        className: "bg-1f648",
        icon: "🙈",
      },
      {
        className: "bg-1f649",
        icon: "🙉",
      },

      {
        className: "bg-1f480",
        icon: "💀",
      },
      {
        className: "bg-1f47d",
        icon: "👽",
      },
      {
        className: "bg-1f63a",
        icon: "😺",
      },
      {
        className: "bg-1f638",
        icon: "😸",
      },
      {
        className: "bg-1f63b",
        icon: "😻",
      },
      {
        className: "bg-1f63d",
        icon: "😽",
      },
      {
        className: "bg-1f63c",
        icon: "😼",
      },
      {
        className: "bg-1f640",
        icon: "🙀",
      },
      {
        className: "bg-1f63f",
        icon: "😿",
      },
      {
        className: "bg-1f639",
        icon: "😹",
      },

      {
        className: "bg-1f63e",
        icon: "😾",
      },
    ],
  },
  {
    name: "食物",
    iconArr: [
      {
        className: "bg-f2615",
        icon: "☕",
      },
      {
        className: "bg-1f375",
        icon: "🍵",
      },
      {
        className: "bg-1f376",
        icon: "🍶",
      },
      {
        className: "bg-1f37c",
        icon: "🍼",
      },
      {
        className: "bg-1f37a",
        icon: "🍺",
      },
      {
        className: "bg-1f37b",
        icon: "🍻",
      },
      {
        className: "bg-1f378",
        icon: "🍸",
      },
      {
        className: "bg-1f379",
        icon: "🍹",
      },
      {
        className: "bg-1f377",
        icon: "🍷",
      },

      {
        className: "bg-1f374",
        icon: "🍴",
      },
      {
        className: "bg-1f355",
        icon: "🍕",
      },
      {
        className: "bg-1f354",
        icon: "🍔",
      },

      {
        className: "bg-1f35f",
        icon: "🍟",
      },
      {
        className: "bg-1f357",
        icon: "🍗",
      },

      {
        className: "bg-1f356",
        icon: "🍖",
      },
      {
        className: "bg-1f35d",
        icon: "🍝",
      },

      {
        className: "bg-1f35b",
        icon: "🍛",
      },
      {
        className: "bg-1f364",
        icon: "🍤",
      },
      {
        className: "bg-1f363",
        icon: "🍣",
      },

      {
        className: "bg-1f365",
        icon: "🍥",
      },
      {
        className: "bg-1f359",
        icon: "🍙",
      },

      {
        className: "bg-1f358",
        icon: "🍘",
      },
      {
        className: "bg-1f35a",
        icon: "🍚",
      },
      {
        className: "bg-1f35c",
        icon: "🍜",
      },

      {
        className: "bg-1f372",
        icon: "🍲",
      },
      {
        className: "bg-1f362",
        icon: "🍢",
      },
      {
        className: "bg-1f361",
        icon: "🍡",
      },
      {
        className: "bg-1f373",
        icon: "🍳",
      },

      {
        className: "bg-1f35e",
        icon: "🍞",
      },
      {
        className: "bg-1f369",
        icon: "🍩",
      },

      {
        className: "bg-1f36e",
        icon: "🍮",
      },
      {
        className: "bg-1f366",
        icon: "🍦",
      },
      {
        className: "bg-1f368",
        icon: "🍨",
      },

      {
        className: "bg-1f367",
        icon: "🍧",
      },
      {
        className: "bg-1f382",
        icon: "🎂",
      },
      {
        className: "bg-1f370",
        icon: "🍰",
      },

      {
        className: "bg-1f36a",
        icon: "🍪",
      },
      {
        className: "bg-1f36b",
        icon: "🍫",
      },
      {
        className: "bg-1f36c",
        icon: "🍬",
      },

      {
        className: "bg-1f36d",
        icon: "🍭",
      },
      {
        className: "bg-1f36f",
        icon: "🍯",
      },
      {
        className: "bg-1f34e",
        icon: "🍎",
      },
      {
        className: "bg-1f34f",
        icon: "🍏",
      },
      {
        className: "bg-1f34a",
        icon: "🍊",
      },
      {
        className: "bg-1f34b",
        icon: "🍋",
      },

      {
        className: "bg-1f352",
        icon: "🍒",
      },
      {
        className: "bg-1f347",
        icon: "🍇",
      },

      {
        className: "bg-1f349",
        icon: "🍉",
      },
      {
        className: "bg-1f353",
        icon: "🍓",
      },
      {
        className: "bg-1f351",
        icon: "🍑",
      },
      {
        className: "bg-1f348",
        icon: "🍈",
      },

      {
        className: "bg-1f34c",
        icon: "🍌",
      },
      {
        className: "bg-1f350",
        icon: "🍐",
      },
      {
        className: "bg-1f34d",
        icon: "🍍",
      },
      {
        className: "bg-1f360",
        icon: "🍠",
      },
      {
        className: "bg-1f346",
        icon: "🍆",
      },
      {
        className: "bg-1f345",
        icon: "🍅",
      },

      {
        className: "bg-1f33d",
        icon: "🌽",
      },
    ],
  },
  {
    name: "自然",
    iconArr: [
      {
        className: "bg-1f490",
        icon: "💐",
      },
      {
        className: "bg-1f338",
        icon: "🌸",
      },
      {
        className: "bg-1f337",
        icon: "🌷",
      },
      {
        className: "bg-1f340",
        icon: "🍀",
      },
      {
        className: "bg-1f339",
        icon: "🌹",
      },
      {
        className: "bg-1f33b",
        icon: "🌻",
      },
      {
        className: "bg-1f33a",
        icon: "🌺",
      },
      {
        className: "bg-1f341",
        icon: "🍁",
      },
      {
        className: "bg-1f343",
        icon: "🍃",
      },
      {
        className: "bg-1f342",
        icon: "🍂",
      },
      {
        className: "bg-1f33f",
        icon: "🌿",
      },
      {
        className: "bg-1f33e",
        icon: "🌾",
      },

      {
        className: "bg-1f344",
        icon: "🍄",
      },
      {
        className: "bg-1f335",
        icon: "🌵",
      },
      {
        className: "bg-1f334",
        icon: "🌴",
      },

      {
        className: "bg-1f332",
        icon: "🌲",
      },
      {
        className: "bg-1f333",
        icon: "🌳",
      },

      {
        className: "bg-1f330",
        icon: "🌰",
      },
      {
        className: "bg-1f331",
        icon: "🌱",
      },
      {
        className: "bg-1f33c",
        icon: "🌼",
      },
      {
        className: "bg-1f310",
        icon: "🌐",
      },

      {
        className: "bg-1f31e",
        icon: "🌞",
      },
      {
        className: "bg-1f31d",
        icon: "🌝",
      },
      {
        className: "bg-1f31a",
        icon: "🌚",
      },

      {
        className: "bg-1f311",
        icon: "🌑",
      },
      {
        className: "bg-1f312",
        icon: "🌒",
      },
      {
        className: "bg-1f313",
        icon: "🌓",
      },

      {
        className: "bg-1f314",
        icon: "🌔",
      },
      {
        className: "bg-1f315",
        icon: "🌕",
      },
      {
        className: "bg-1f316",
        icon: "🌖",
      },
      {
        className: "bg-1f317",
        icon: "🌗",
      },
      {
        className: "bg-1f31c",
        icon: "🌜",
      },

      {
        className: "bg-1f31b",
        icon: "🌛",
      },
      {
        className: "bg-1f319",
        icon: "🌙",
      },
      {
        className: "bg-1f30d",
        icon: "🌍",
      },
      {
        className: "bg-1f30e",
        icon: "🌎",
      },
      {
        className: "bg-1f30f",
        icon: "🌏",
      },

      {
        className: "bg-1f30b",
        icon: "🌋",
      },
      {
        className: "bg-1f30c",
        icon: "🌌",
      },
      {
        className: "bg-1f320",
        icon: "🌠",
      },
      {
        className: "bg-f2b50",
        icon: "⭐",
      },

      {
        className: "bg-f2600",
        icon: "☀",
      },

      {
        className: "bg-f26c5",
        icon: "⛅",
      },
      {
        className: "bg-f2601",
        icon: "☁",
      },
      {
        className: "bg-f26a1",
        icon: "⚡",
      },

      {
        className: "bg-f2614",
        icon: "☔",
      },
      {
        className: "bg-f2744",
        icon: "❄",
      },
      {
        className: "bg-f26c4",
        icon: "⛄",
      },

      {
        className: "bg-1f300",
        icon: "🌀",
      },
      {
        className: "bg-1f301",
        icon: "🌁",
      },
      {
        className: "bg-1f308",
        icon: "🌈",
      },
      {
        className: "bg-1f30a",
        icon: "🌊",
      },

      {
        className: "bg-1f525",
        icon: "🔥",
      },
      {
        className: "bg-f2728",
        icon: "✨",
      },
      {
        className: "bg-1f31f",
        icon: "🌟",
      },
      {
        className: "bg-1f4ab",
        icon: "💫",
      },
      {
        className: "bg-1f4a5",
        icon: "💥",
      },
      {
        className: "bg-1f4a2",
        icon: "💢",
      },
      {
        className: "bg-1f4a6",
        icon: "💦",
      },
      {
        className: "bg-1f4a7",
        icon: "💧",
      },
      {
        className: "bg-1f318",
        icon: "🌘",
      },
      {
        className: "bg-1f4a4",
        icon: "💤",
      },
      {
        className: "bg-1f4a8",
        icon: "💨",
      },
    ],
  },
  {
    name: "生肖",
    iconArr: [
      {
        className: "bg-1f401",
        icon: "🐁",
      },
      {
        className: "bg-1f402",
        icon: "🐂",
      },
      {
        className: "bg-1f405",
        icon: "🐅",
      },
      {
        className: "bg-1f407",
        icon: "🐇",
      },
      {
        className: "bg-1f409",
        icon: "🐉",
      },
      {
        className: "bg-1f40d",
        icon: "🐍",
      },
      {
        className: "bg-1f40e",
        icon: "🐎",
      },
      {
        className: "bg-1f410",
        icon: "🐐",
      },
      {
        className: "bg-1f412",
        icon: "🐒",
      },
      {
        className: "bg-1f413",
        icon: "🐓",
      },
      {
        className: "bg-1f415",
        icon: "🐕",
      },
      {
        className: "bg-1f416",
        icon: "🐖",
      },
      {
        className: "bg-f2648",
        icon: "♈",
      },
      {
        className: "bg-f2649",
        icon: "♉",
      },
      {
        className: "bg-f264a",
        icon: "♊",
      },
      {
        className: "bg-f264b",
        icon: "♋",
      },
      {
        className: "bg-f264c",
        icon: "♌",
      },
      {
        className: "bg-f264d",
        icon: "♍",
      },
      {
        className: "bg-f264e",
        icon: "♎",
      },
      {
        className: "bg-f264f",
        icon: "♏",
      },
      {
        className: "bg-f2650",
        icon: "♐",
      },
      {
        className: "bg-f2651",
        icon: "♑",
      },
      {
        className: "bg-f2652",
        icon: "♒",
      },
      {
        className: "bg-f2653",
        icon: "♓",
      },
    ],
  },
  {
    name: "运动",
    iconArr: [
      {
        className: "bg-1f4f0",
        icon: "📰",
      },
      {
        className: "bg-1f3a8",
        icon: "🎨",
      },
      {
        className: "bg-1f3ac",
        icon: "🎬",
      },
      {
        className: "bg-1f3a4",
        icon: "🎤",
      },
      {
        className: "bg-1f3a7",
        icon: "🎧",
      },
      {
        className: "bg-1f3bc",
        icon: "🎼",
      },
      {
        className: "bg-1f3b5",
        icon: "🎵",
      },
      {
        className: "bg-1f3b6",
        icon: "🎶",
      },
      {
        className: "bg-1f3b9",
        icon: "🎹",
      },
      {
        className: "bg-1f3bb",
        icon: "🎻",
      },
      {
        className: "bg-1f3b7",
        icon: "🎷",
      },
      {
        className: "bg-1f3b8",
        icon: "🎸",
      },
      {
        className: "bg-1f47e",
        icon: "👾",
      },
      {
        className: "bg-1f3ae",
        icon: "🎮",
      },
      {
        className: "bg-1f0cf",
        icon: "🃏",
      },
      {
        className: "bg-1f3b4",
        icon: "🎴",
      },
      {
        className: "bg-1f004",
        icon: "🀄",
      },
      {
        className: "bg-1f3b2",
        icon: "🎲",
      },
      {
        className: "bg-1f3af",
        icon: "🎯",
      },
      {
        className: "bg-1f3c8",
        icon: "🏈",
      },
      {
        className: "bg-1f3c0",
        icon: "🏀",
      },
      {
        className: "bg-f26bd",
        icon: "⚽",
      },
      {
        className: "bg-f26be",
        icon: "⚾",
      },
      {
        className: "bg-1f3be",
        icon: "🎾",
      },
      {
        className: "bg-1f3b1",
        icon: "🎱",
      },
      {
        className: "bg-1f3c9",
        icon: "🏉",
      },
      {
        className: "bg-1f3b3",
        icon: "🎳",
      },
      {
        className: "bg-f26f3",
        icon: "⛳",
      },
      {
        className: "bg-1f6b5",
        icon: "🚵",
      },
      {
        className: "bg-1f6b4",
        icon: "🚴",
      },
      {
        className: "bg-1f3c1",
        icon: "🏁",
      },
      {
        className: "bg-1f3c7",
        icon: "🏇",
      },
      {
        className: "bg-1f3c6",
        icon: "🏆",
      },
      {
        className: "bg-1f3bf",
        icon: "🎿",
      },
      {
        className: "bg-1f3c2",
        icon: "🏂",
      },
      {
        className: "bg-1f3ca",
        icon: "🏊",
      },
      {
        className: "bg-1f3c4",
        icon: "🏄",
      },
      {
        className: "bg-1f3a3",
        icon: "🎣",
      },
    ],
  },
  {
    name: "人物",
    iconArr: [
      {
        className: "bg-1f442",
        icon: "👂",
      },
      {
        className: "bg-1f440",
        icon: "👀",
      },
      {
        className: "bg-1f443",
        icon: "👃",
      },
      {
        className: "bg-1f445",
        icon: "👅",
      },
      {
        className: "bg-1f444",
        icon: "👄",
      },
      {
        className: "bg-1f44d",
        icon: "👍",
      },
      {
        className: "bg-1f44e",
        icon: "👎",
      },
      {
        className: "bg-1f44c",
        icon: "👌",
      },
      {
        className: "bg-1f44a",
        icon: "👊",
      },
      {
        className: "bg-f270a",
        icon: "✊",
      },
      {
        className: "bg-f270c",
        icon: "✌",
      },
      {
        className: "bg-1f44b",
        icon: "👋",
      },
      {
        className: "bg-f270b",
        icon: "✋",
      },
      {
        className: "bg-1f450",
        icon: "👐",
      },
      {
        className: "bg-1f446",
        icon: "👆",
      },
      {
        className: "bg-1f447",
        icon: "👇",
      },
      {
        className: "bg-1f449",
        icon: "👉",
      },
      {
        className: "bg-1f448",
        icon: "👈",
      },
      {
        className: "bg-1f64c",
        icon: "🙌",
      },
      {
        className: "bg-1f64f",
        icon: "🙏",
      },
      {
        className: "bg-f261d",
        icon: "☝",
      },
      {
        className: "bg-1f44f",
        icon: "👏",
      },
      {
        className: "bg-1f6b6",
        icon: "🚶",
      },
      {
        className: "bg-1f4aa",
        icon: "💪",
      },
      {
        className: "bg-1f3c3",
        icon: "🏃",
      },
      {
        className: "bg-1f483",
        icon: "💃",
      },
      {
        className: "bg-1f46b",
        icon: "👫",
      },
      {
        className: "bg-1f46a",
        icon: "👪",
      },
      {
        className: "bg-1f46c",
        icon: "👬",
      },
      {
        className: "bg-1f46d",
        icon: "👭",
      },
      {
        className: "bg-1f48f",
        icon: "💏",
      },
      {
        className: "bg-1f491",
        icon: "💑",
      },
      {
        className: "bg-1f46f",
        icon: "👯",
      },
      {
        className: "bg-1f645",
        icon: "🙆",
      },
      {
        className: "bg-1f646",
        icon: "🙅",
      },
      {
        className: "bg-1f481",
        icon: "💁",
      },
      {
        className: "bg-1f64b",
        icon: "🙋",
      },
      {
        className: "bg-1f487",
        icon: "💇",
      },
      {
        className: "bg-1f485",
        icon: "💅",
      },
      {
        className: "bg-1f470",
        icon: "👰",
      },
      {
        className: "bg-1f64e",
        icon: "🙎",
      },
      {
        className: "bg-1f64d",
        icon: "🙍",
      },
      {
        className: "bg-1f647",
        icon: "🙇",
      },
      {
        className: "bg-1f3a9",
        icon: "🎩",
      },
      {
        className: "bg-1f451",
        icon: "👑",
      },
      {
        className: "bg-1f452",
        icon: "👒",
      },
      {
        className: "bg-1f45f",
        icon: "👟",
      },
      {
        className: "bg-1f45e",
        icon: "👞",
      },
      {
        className: "bg-1f461",
        icon: "👡",
      },
      {
        className: "bg-1f460",
        icon: "👠",
      },
      {
        className: "bg-1f462",
        icon: "👢",
      },
      {
        className: "bg-1f455",
        icon: "👕",
      },
      {
        className: "bg-1f454",
        icon: "👔",
      },
      {
        className: "bg-1f45a",
        icon: "👚",
      },
      {
        className: "bg-1f457",
        icon: "👗",
      },
      {
        className: "bg-1f3bd",
        icon: "🎽",
      },
      {
        className: "bg-1f456",
        icon: "👖",
      },
      {
        className: "bg-1f458",
        icon: "👘",
      },
      {
        className: "bg-1f459",
        icon: "👙",
      },
      {
        className: "bg-1f4bc",
        icon: "💼",
      },
      {
        className: "bg-1f45c",
        icon: "👜",
      },
      {
        className: "bg-1f45d",
        icon: "👝",
      },
      {
        className: "bg-1f45b",
        icon: "👛",
      },
      {
        className: "bg-1f453",
        icon: "👓",
      },
      {
        className: "bg-1f380",
        icon: "🎀",
      },
      {
        className: "bg-1f302",
        icon: "🌂",
      },
      {
        className: "bg-1f484",
        icon: "💄",
      },
      {
        className: "bg-1f48b",
        icon: "💋",
      },
      {
        className: "bg-1f463",
        icon: "👣",
      },
      {
        className: "bg-1f48e",
        icon: "💎",
      },
      {
        className: "bg-1f48d",
        icon: "💍",
      },
    ],
  },
  {
    name: "花样",
    iconArr: [
      {
        className: "bg-1f451",
        icon: "👑",
      },
      {
        className: "bg-1f525",
        icon: "🔥",
      },
      {
        className: "bg-2728",
        icon: "✨",
      },
      {
        className: "bg-1f31f",
        icon: "🌟",
      },
      {
        className: "bg-1f4ab",
        icon: "💫",
      },
      {
        className: "bg-1f4a5",
        icon: "💥",
      },
      {
        className: "bg-1f380",
        icon: "🎀",
      },
      {
        className: "bg-1f302",
        icon: "🌂",
      },
      {
        className: "bg-1f484",
        icon: "💄",
      },
      {
        className: "bg-1f49b",
        icon: "💛",
      },
      {
        className: "bg-1f499",
        icon: "💙",
      },
      {
        className: "bg-1f49c",
        icon: "💜",
      },
      {
        className: "bg-1f49a",
        icon: "💚",
      },
      {
        className: "bg-f2764",
        icon: "❤",
      },
      {
        className: "bg-1f494",
        icon: "💔",
      },
      {
        className: "bg-1f497",
        icon: "💗",
      },
      {
        className: "bg-1f493",
        icon: "💓",
      },
      {
        className: "bg-1f495",
        icon: "💕",
      },
      {
        className: "bg-1f496",
        icon: "💖",
      },
      {
        className: "bg-1f49e",
        icon: "💞",
      },
      {
        className: "bg-1f498",
        icon: "💘",
      },
      {
        className: "bg-1f48c",
        icon: "💌",
      },
      {
        className: "bg-1f48b",
        icon: "💋",
      },
      {
        className: "bg-1f38d",
        icon: "🎍",
      },
      {
        className: "bg-1f49d",
        icon: "💝",
      },
      {
        className: "bg-1f38e",
        icon: "🎎",
      },
      {
        className: "bg-1f392",
        icon: "🎒",
      },
      {
        className: "bg-1f393",
        icon: "🎓",
      },
      {
        className: "bg-1f38f",
        icon: "🎏",
      },
      {
        className: "bg-1f386",
        icon: "🎆",
      },
      {
        className: "bg-1f387",
        icon: "🎇",
      },
      {
        className: "bg-1f390",
        icon: "🎐",
      },
      {
        className: "bg-1f391",
        icon: "🎑",
      },
      {
        className: "bg-1f383",
        icon: "🎃",
      },
      {
        className: "bg-1f47b",
        icon: "👻",
      },
      {
        className: "bg-1f385",
        icon: "🎅",
      },
      {
        className: "bg-1f384",
        icon: "🎄",
      },
      {
        className: "bg-1f381",
        icon: "🎁",
      },
      {
        className: "bg-1f38b",
        icon: "🎋",
      },
      {
        className: "bg-1f389",
        icon: "🎉",
      },
      {
        className: "bg-1f38a",
        icon: "🎊",
      },
      {
        className: "bg-1f388",
        icon: "🎈",
      },
      {
        className: "bg-1f38c",
        icon: "🎌",
      },
    ],
  },
  {
    name: "文字",
    iconArr: [
      {
        className: "bg-1f22f",
        icon: "🈯",
      },
      {
        className: "bg-1f233",
        icon: "🈳",
      },
      {
        className: "bg-1f235",
        icon: "🈵",
      },
      {
        className: "bg-1f234",
        icon: "🈴",
      },
      {
        className: "bg-1f232",
        icon: "🈲",
      },
      {
        className: "bg-1f250",
        icon: "🉐",
      },

      {
        className: "bg-1f239",
        icon: "🈹",
      },
      {
        className: "bg-1f23a",
        icon: "🈺",
      },
      {
        className: "bg-1f236",
        icon: "🈶",
      },
      {
        className: "bg-1f21a",
        icon: "🈚",
      },
      {
        className: "bg-1f6be",
        icon: "🚾",
      },
      {
        className: "bg-1f17f",
        icon: "🅿",
      },

      {
        className: "bg-1f237",
        icon: "🈷",
      },
      {
        className: "bg-1f238",
        icon: "🈸",
      },
      {
        className: "bg-1f202",
        icon: "🈂",
      },
      {
        className: "bg-f24c2",
        icon: "Ⓜ",
      },
      {
        className: "bg-1f251",
        icon: "🉑",
      },
      {
        className: "bg-f3299",
        icon: "㊙",
      },

      {
        className: "bg-f3297",
        icon: "㊗",
      },
      {
        className: "bg-1f191",
        icon: "🆑",
      },
      {
        className: "bg-1f198",
        icon: "🆘",
      },
      {
        className: "bg-1f194",
        icon: "🆔",
      },
      {
        className: "bg-1f51e",
        icon: "🔞",
      },
      {
        className: "bg-1f6ab",
        icon: "🚫",
      },

      {
        className: "bg-1f19a",
        icon: "🆚",
      },
      {
        className: "bg-1f171",
        icon: "🅱",
      },
      {
        className: "bg-1f170",
        icon: "🅰",
      },
      {
        className: "bg-1f18e",
        icon: "🆎",
      },
      {
        className: "bg-1f17e",
        icon: "🅾",
      },
      {
        className: "bg-f2747",
        icon: "❇",
      },
    ],
  },
  {
    name: "物件",
    iconArr: [
      {
        className: "bg-1f52e",
        icon: "🔮",
      },
      {
        className: "bg-1f3a5",
        icon: "🎥",
      },
      {
        className: "bg-1f4f7",
        icon: "📷",
      },
      {
        className: "bg-1f4f9",
        icon: "📹",
      },
      {
        className: "bg-1f4fc",
        icon: "📼",
      },
      {
        className: "bg-1f4bf",
        icon: "💿",
      },
      {
        className: "bg-1f4c0",
        icon: "📀",
      },
      {
        className: "bg-1f4bd",
        icon: "💽",
      },
      {
        className: "bg-1f4be",
        icon: "💾",
      },
      {
        className: "bg-1f4bb",
        icon: "💻",
      },
      {
        className: "bg-1f4f1",
        icon: "📱",
      },
      {
        className: "bg-f260e",
        icon: "☎",
      },
      {
        className: "bg-1f4de",
        icon: "📞",
      },
      {
        className: "bg-1f4df",
        icon: "📟",
      },
      {
        className: "bg-1f4e0",
        icon: "📠",
      },
      {
        className: "bg-1f4e1",
        icon: "📡",
      },
      {
        className: "bg-1f4fa",
        icon: "📺",
      },
      {
        className: "bg-1f4fb",
        icon: "📻",
      },
      {
        className: "bg-1f50a",
        icon: "🔊",
      },
      {
        className: "bg-1f509",
        icon: "🔉",
      },
      {
        className: "bg-1f508",
        icon: "🔈",
      },
      {
        className: "bg-1f507",
        icon: "🔇",
      },
      {
        className: "bg-1f514",
        icon: "🔔",
      },
      {
        className: "bg-1f515",
        icon: "🔕",
      },
      {
        className: "bg-1f4e2",
        icon: "📢",
      },
      {
        className: "bg-1f4e3",
        icon: "📣",
      },
      {
        className: "bg-f23f3",
        icon: "⏳",
      },
      {
        className: "bg-f231b",
        icon: "⌛",
      },
      {
        className: "bg-f23f0",
        icon: "⏰",
      },
      {
        className: "bg-f231a",
        icon: "⌚",
      },
      {
        className: "bg-1f513",
        icon: "🔓",
      },
      {
        className: "bg-1f512",
        icon: "🔒",
      },
      {
        className: "bg-1f510",
        icon: "🔐",
      },
      {
        className: "bg-1f511",
        icon: "🔑",
      },
      {
        className: "bg-1f50e",
        icon: "🔎",
      },
      {
        className: "bg-1f4a1",
        icon: "💡",
      },
      {
        className: "bg-1f526",
        icon: "🔦",
      },
      {
        className: "bg-1f506",
        icon: "🔆",
      },
      {
        className: "bg-1f505",
        icon: "🔅",
      },
      {
        className: "bg-1f50c",
        icon: "🔌",
      },
      {
        className: "bg-1f50b",
        icon: "🔋",
      },
      {
        className: "bg-1f50d",
        icon: "🔍",
      },
      {
        className: "bg-1f6c1",
        icon: "🛁",
      },
      {
        className: "bg-1f6c0",
        icon: "🛀",
      },
      {
        className: "bg-1f6bf",
        icon: "🚿",
      },
      {
        className: "bg-1f6bd",
        icon: "🚽",
      },
      {
        className: "bg-1f527",
        icon: "🔧",
      },
      {
        className: "bg-1f529",
        icon: "🔩",
      },
      {
        className: "bg-1f528",
        icon: "🔨",
      },
      {
        className: "bg-1f6aa",
        icon: "🚪",
      },
      {
        className: "bg-1f6ac",
        icon: "🚬",
      },
      {
        className: "bg-1f4a3",
        icon: "💣",
      },
      {
        className: "bg-1f52b",
        icon: "🔫",
      },
      {
        className: "bg-1f52a",
        icon: "🔪",
      },
      {
        className: "bg-1f48a",
        icon: "💊",
      },
      {
        className: "bg-1f4b0",
        icon: "💰",
      },
      {
        className: "bg-1f4b4",
        icon: "💴",
      },
      {
        className: "bg-1f4b5",
        icon: "💵",
      },
      {
        className: "bg-1f4b7",
        icon: "💷",
      },
      {
        className: "bg-1f4b6",
        icon: "💶",
      },
      {
        className: "bg-1f4b3",
        icon: "💳",
      },
      {
        className: "bg-1f4b8",
        icon: "💸",
      },
      {
        className: "bg-1f4f2",
        icon: "📲",
      },
      {
        className: "bg-1f4e7",
        icon: "📧",
      },
      {
        className: "bg-1f4e5",
        icon: "📥",
      },
      {
        className: "bg-1f4e4",
        icon: "📤",
      },
      {
        className: "bg-f2709",
        icon: "✉",
      },
      {
        className: "bg-1f4e9",
        icon: "📩",
      },
      {
        className: "bg-1f4e8",
        icon: "📨",
      },
      {
        className: "bg-1f4ef",
        icon: "📯",
      },
      {
        className: "bg-1f4eb",
        icon: "📫",
      },
      {
        className: "bg-1f4ea",
        icon: "📪",
      },
      {
        className: "bg-1f4ec",
        icon: "📬",
      },
      {
        className: "bg-1f4ed",
        icon: "📭",
      },
      {
        className: "bg-1f4ee",
        icon: "📮",
      },
      {
        className: "bg-1f4e6",
        icon: "📦",
      },
      {
        className: "bg-1f4dd",
        icon: "📝",
      },
      {
        className: "bg-1f4c4",
        icon: "📄",
      },
      {
        className: "bg-1f4c3",
        icon: "📃",
      },
      {
        className: "bg-1f4d1",
        icon: "📑",
      },
      {
        className: "bg-1f4ca",
        icon: "📊",
      },
      {
        className: "bg-1f4c8",
        icon: "📈",
      },
      {
        className: "bg-1f4c9",
        icon: "📉",
      },
      {
        className: "bg-1f4dc",
        icon: "📜",
      },
      {
        className: "bg-1f4cb",
        icon: "📋",
      },
      {
        className: "bg-1f4c5",
        icon: "📅",
      },
      {
        className: "bg-1f4c6",
        icon: "📆",
      },
      {
        className: "bg-1f4c7",
        icon: "📇",
      },
      {
        className: "bg-1f4c1",
        icon: "📁",
      },
      {
        className: "bg-1f4c2",
        icon: "📂",
      },
      {
        className: "bg-f2702",
        icon: "✂",
      },
      {
        className: "bg-1f4cc",
        icon: "📌",
      },
      {
        className: "bg-1f4ce",
        icon: "📎",
      },
      {
        className: "bg-f2712",
        icon: "✒",
      },
      {
        className: "bg-f270f",
        icon: "✏",
      },
      {
        className: "bg-1f4cf",
        icon: "📏",
      },
      {
        className: "bg-1f4d0",
        icon: "📐",
      },
      {
        className: "bg-1f4d5",
        icon: "📕",
      },
      {
        className: "bg-1f4d7",
        icon: "📗",
      },
      {
        className: "bg-1f4d8",
        icon: "📘",
      },
      {
        className: "bg-1f4d9",
        icon: "📙",
      },
      {
        className: "bg-1f4d3",
        icon: "📓",
      },
      {
        className: "bg-1f4d4",
        icon: "📔",
      },
      {
        className: "bg-1f4d2",
        icon: "📒",
      },
      {
        className: "bg-1f4da",
        icon: "📚",
      },
      {
        className: "bg-1f4d6",
        icon: "📖",
      },
      {
        className: "bg-1f516",
        icon: "🔖",
      },
      {
        className: "bg-1f4db",
        icon: "📛",
      },
      {
        className: "bg-1f52c",
        icon: "🔬",
      },
    ],
  },
  {
    name: "标识",
    iconArr: [
      {
        className: "bg-1f6bb",
        icon: "🚻",
      },
      {
        className: "bg-1f6b9",
        icon: "🚹",
      },
      {
        className: "bg-1f6ba",
        icon: "🚺",
      },
      {
        className: "bg-1f6bc",
        icon: "🚼",
      },
      {
        className: "bg-1f6b0",
        icon: "🚰",
      },
      {
        className: "bg-1f6ae",
        icon: "🚮",
      },
      {
        className: "bg-f267f",
        icon: "♿",
      },
      {
        className: "bg-1f6ad",
        icon: "🚭",
      },
      {
        className: "bg-1f6c2",
        icon: "🛂",
      },
      {
        className: "bg-1f6c4",
        icon: "🛄",
      },
      {
        className: "bg-1f6c5",
        icon: "🛅",
      },
      {
        className: "bg-1f6c3",
        icon: "🛃",
      },
      {
        className: "bg-1f6ab",
        icon: "🚫",
      },
      {
        className: "bg-1f51e",
        icon: "🔞",
      },
      {
        className: "bg-1f6af",
        icon: "🚯",
      },
      {
        className: "bg-1f6b1",
        icon: "🚱",
      },
      {
        className: "bg-1f6b3",
        icon: "🚳",
      },
      {
        className: "bg-1f6b7",
        icon: "🚷",
      },
      {
        className: "bg-1f6b8",
        icon: "🚸",
      },
      {
        className: "bg-f26d4",
        icon: "⛔",
      },
      {
        className: "bg-f2733",
        icon: "✳",
      },
      {
        className: "bg-f2747",
        icon: "❇",
      },
      {
        className: "bg-f274e",
        icon: "❎",
      },
      {
        className: "bg-f2705",
        icon: "✅",
      },
      {
        className: "bg-f2734",
        icon: "✴",
      },
      {
        className: "bg-1f49f",
        icon: "💟",
      },
      {
        className: "bg-1f4f3",
        icon: "📳",
      },
      {
        className: "bg-1f4f4",
        icon: "📴",
      },
      {
        className: "bg-1f4a0",
        icon: "💠",
      },
      {
        className: "bg-f27bf",
        icon: "➿",
      },
      {
        className: "bg-f267b",
        icon: "♻",
      },
      {
        className: "bg-f26ce",
        icon: "⛎",
      },
      {
        className: "bg-1f51f",
        icon: "🔟",
      },

      {
        className: "bg-f2b06",
        icon: "⬆",
      },
      {
        className: "bg-f2b07",
        icon: "⬇",
      },
      {
        className: "bg-f2b05",
        icon: "⬅",
      },
      {
        className: "bg-f27a1",
        icon: "➡",
      },
      {
        className: "bg-1f523",
        icon: "🔣",
      },
      {
        className: "bg-1f522",
        icon: "🔢",
      },
      {
        className: "bg-1f520",
        icon: "🔠",
      },
      {
        className: "bg-1f521",
        icon: "🔡",
      },
      {
        className: "bg-1f524",
        icon: "🔤",
      },
      {
        className: "bg-f2197",
        icon: "↗",
      },
      {
        className: "bg-f2196",
        icon: "↖",
      },
      {
        className: "bg-f2198",
        icon: "↘",
      },
      {
        className: "bg-f2199",
        icon: "↙",
      },
      {
        className: "bg-f2194",
        icon: "↔",
      },
      {
        className: "bg-f2195",
        icon: "↕",
      },
      {
        className: "bg-1f504",
        icon: "🔄",
      },
      {
        className: "bg-f25c0",
        icon: "◀",
      },
      {
        className: "bg-f25b6",
        icon: "▶",
      },
      {
        className: "bg-1f53c",
        icon: "🔼",
      },
      {
        className: "bg-1f53d",
        icon: "🔽",
      },
      {
        className: "bg-f21a9",
        icon: "↩",
      },
      {
        className: "bg-f21aa",
        icon: "↪",
      },
      {
        className: "bg-f2139",
        icon: "ℹ",
      },
      {
        className: "bg-f23ea",
        icon: "⏪",
      },
      {
        className: "bg-f23eb",
        icon: "⏫",
      },
      {
        className: "bg-f23ec",
        icon: "⏬",
      },
      {
        className: "bg-f2935",
        icon: "⤵",
      },
      {
        className: "bg-f2934",
        icon: "⤴",
      },
      {
        className: "bg-1f197",
        icon: "🆗",
      },
      {
        className: "bg-1f500",
        icon: "🔀",
      },
      {
        className: "bg-1f501",
        icon: "🔁",
      },
      {
        className: "bg-1f502",
        icon: "🔂",
      },
      {
        className: "bg-1f195",
        icon: "🆕",
      },
      {
        className: "bg-1f199",
        icon: "🆙",
      },
      {
        className: "bg-1f192",
        icon: "🆒",
      },
      {
        className: "bg-1f193",
        icon: "🆓",
      },
      {
        className: "bg-1f196",
        icon: "🆖",
      },
      {
        className: "bg-1f4f6",
        icon: "📶",
      },
      {
        className: "bg-1f3a6",
        icon: "🎦",
      },
      {
        className: "bg-1f201",
        icon: "🈁",
      },
      {
        className: "bg-1f52f",
        icon: "🔯",
      },
      {
        className: "bg-1f3e7",
        icon: "🏧",
      },
      {
        className: "bg-1f4b9",
        icon: "💹",
      },
      {
        className: "bg-1f4b2",
        icon: "💲",
      },
      {
        className: "bg-1f4b1",
        icon: "💱",
      },
      {
        className: "bg-f274c",
        icon: "❌",
      },
      {
        className: "bg-f2757",
        icon: "❗",
      },
      {
        className: "bg-f2753",
        icon: "❓",
      },
      {
        className: "bg-f2755",
        icon: "❕",
      },
      {
        className: "bg-f2754",
        icon: "❔",
      },
      {
        className: "bg-f2b55",
        icon: "⭕",
      },
      {
        className: "bg-1f51d",
        icon: "🔝",
      },
      {
        className: "bg-1f51a",
        icon: "🔚",
      },
      {
        className: "bg-1f519",
        icon: "🔙",
      },
      {
        className: "bg-1f51b",
        icon: "🔛",
      },
      {
        className: "bg-1f51c",
        icon: "🔜",
      },
      {
        className: "bg-1f503",
        icon: "🔃",
      },
      {
        className: "bg-1f55b",
        icon: "🕛",
      },
      {
        className: "bg-1f551",
        icon: "🕑",
      },
      {
        className: "bg-1f552",
        icon: "🕒",
      },
      {
        className: "bg-1f55e",
        icon: "🕞",
      },
      {
        className: "bg-1f553",
        icon: "🕓",
      },
      {
        className: "bg-f2795",
        icon: "➕",
      },
      {
        className: "bg-f2796",
        icon: "➖",
      },
      {
        className: "bg-f2797",
        icon: "➗",
      },
      {
        className: "bg-f2660",
        icon: "♠",
      },
      {
        className: "bg-f2665",
        icon: "♥",
      },
      {
        className: "bg-f2663",
        icon: "♣",
      },
      {
        className: "bg-f2666",
        icon: "♦",
      },
      {
        className: "bg-1f4ae",
        icon: "💮",
      },
      {
        className: "bg-1f4af",
        icon: "💯",
      },
      {
        className: "bg-f2714",
        icon: "✔",
      },
      {
        className: "bg-f2611",
        icon: "☑",
      },
      {
        className: "bg-1f518",
        icon: "🔘",
      },
      {
        className: "bg-1f517",
        icon: "🔗",
      },
      {
        className: "bg-f27b0",
        icon: "➰",
      },
      {
        className: "bg-f3030",
        icon: "〰",
      },
      {
        className: "bg-f303d",
        icon: "〽",
      },
      {
        className: "bg-1f531",
        icon: "🔱",
      },
      {
        className: "bg-f25fc",
        icon: "◼",
      },
      {
        className: "bg-f25fb",
        icon: "◻",
      },
      {
        className: "bg-f25fe",
        icon: "◾",
      },
      {
        className: "bg-f25fd",
        icon: "◽",
      },
      {
        className: "bg-f25aa",
        icon: "▪",
      },
      {
        className: "bg-f25ab",
        icon: "▫",
      },
      {
        className: "bg-1f53a",
        icon: "🔺",
      },
      {
        className: "bg-1f532",
        icon: "🔲",
      },
      {
        className: "bg-1f533",
        icon: "🔳",
      },
      {
        className: "bg-f26ab",
        icon: "⚫",
      },
      {
        className: "bg-f26aa",
        icon: "⚪",
      },
      {
        className: "bg-1f534",
        icon: "🔴",
      },
      {
        className: "bg-1f535",
        icon: "🔵",
      },
      {
        className: "bg-1f53b",
        icon: "🔻",
      },
      {
        className: "bg-1f536",
        icon: "🔶",
      },
      {
        className: "bg-1f537",
        icon: "🔷",
      },
      {
        className: "bg-1f538",
        icon: "🔸",
      },
      {
        className: "bg-1f539",
        icon: "🔹",
      },
      {
        className: "bg-f2716",
        icon: "✖",
      },
    ],
  },
  {
    name: "房车",
    iconArr: [
      {
        className: "bg-1f3e0",
        icon: "🏠",
      },
      {
        className: "bg-1f3e1",
        icon: "🏡",
      },
      {
        className: "bg-1f3eb",
        icon: "🏫",
      },
      {
        className: "bg-1f3e2",
        icon: "🏢",
      },
      {
        className: "bg-1f3e3",
        icon: "🏣",
      },
      {
        className: "bg-1f3e5",
        icon: "🏥",
      },
      {
        className: "bg-1f3e6",
        icon: "🏦",
      },
      {
        className: "bg-1f3ea",
        icon: "🏪",
      },
      {
        className: "bg-1f3e9",
        icon: "🏩",
      },
      {
        className: "bg-1f3e8",
        icon: "🏨",
      },
      {
        className: "bg-1f492",
        icon: "💒",
      },
      {
        className: "bg-f26ea",
        icon: "⛪",
      },
      {
        className: "bg-1f3ec",
        icon: "🏬",
      },
      {
        className: "bg-1f3e4",
        icon: "🏤",
      },
      {
        className: "bg-1f307",
        icon: "🌇",
      },
      {
        className: "bg-1f306",
        icon: "🌆",
      },
      {
        className: "bg-1f3ef",
        icon: "🏯",
      },
      {
        className: "bg-1f3f0",
        icon: "🏰",
      },
      {
        className: "bg-f26fa",
        icon: "⛺",
      },
      {
        className: "bg-1f3ed",
        icon: "🏭",
      },
      {
        className: "bg-1f5fc",
        icon: "🗼",
      },
      {
        className: "bg-1f5fe",
        icon: "🗾",
      },
      {
        className: "bg-1f5fb",
        icon: "🗻",
      },
      {
        className: "bg-1f304",
        icon: "🌄",
      },
      {
        className: "bg-1f6a2",
        icon: "🚢",
      },
      {
        className: "bg-f26f5",
        icon: "⛵",
      },
      {
        className: "bg-1f6a4",
        icon: "🚤",
      },
      {
        className: "bg-1f6a3",
        icon: "🚣",
      },
      {
        className: "bg-f2693",
        icon: "⚓",
      },
      {
        className: "bg-1f680",
        icon: "🚀",
      },
      {
        className: "bg-f2708",
        icon: "✈",
      },
      {
        className: "bg-1f4ba",
        icon: "💺",
      },
      {
        className: "bg-1f681",
        icon: "🚁",
      },
      {
        className: "bg-1f682",
        icon: "🚂",
      },
      {
        className: "bg-1f68a",
        icon: "🚊",
      },
      {
        className: "bg-1f689",
        icon: "🚉",
      },
      {
        className: "bg-1f69e",
        icon: "🚞",
      },
      {
        className: "bg-1f686",
        icon: "🚆",
      },
      {
        className: "bg-1f684",
        icon: "🚄",
      },
      {
        className: "bg-1f685",
        icon: "🚅",
      },
      {
        className: "bg-1f688",
        icon: "🚈",
      },
      {
        className: "bg-1f687",
        icon: "🚇",
      },
      {
        className: "bg-1f69d",
        icon: "🚝",
      },
      {
        className: "bg-1f68b",
        icon: "🚋",
      },
      {
        className: "bg-1f683",
        icon: "🚃",
      },
      {
        className: "bg-1f68e",
        icon: "🚎",
      },
      {
        className: "bg-1f68c",
        icon: "🚌",
      },
      {
        className: "bg-1f68d",
        icon: "🚍",
      },
      {
        className: "bg-1f699",
        icon: "🚙",
      },
      {
        className: "bg-1f698",
        icon: "🚘",
      },
      {
        className: "bg-1f697",
        icon: "🚗",
      },
      {
        className: "bg-1f695",
        icon: "🚕",
      },
      {
        className: "bg-1f696",
        icon: "🚖",
      },
      {
        className: "bg-1f69b",
        icon: "🚛",
      },
      {
        className: "bg-1f69a",
        icon: "🚚",
      },
      {
        className: "bg-1f6a8",
        icon: "🚨",
      },
      {
        className: "bg-1f693",
        icon: "🚓",
      },
      {
        className: "bg-1f694",
        icon: "🚔",
      },
      {
        className: "bg-1f692",
        icon: "🚒",
      },
      {
        className: "bg-1f691",
        icon: "🚑",
      },
      {
        className: "bg-1f690",
        icon: "🚐",
      },
      {
        className: "bg-1f6b2",
        icon: "🚲",
      },
      {
        className: "bg-1f6a1",
        icon: "🚡",
      },
      {
        className: "bg-1f69f",
        icon: "🚟",
      },
      {
        className: "bg-1f6a0",
        icon: "🚠",
      },
      {
        className: "bg-1f69c",
        icon: "🚜",
      },
      {
        className: "bg-1f488",
        icon: "💈",
      },
      {
        className: "bg-1f68f",
        icon: "🚏",
      },
      {
        className: "bg-1f3ab",
        icon: "🎫",
      },
      {
        className: "bg-1f6a6",
        icon: "🚦",
      },
      {
        className: "bg-1f6a5",
        icon: "🚥",
      },
      {
        className: "bg-f26a0",
        icon: "⚠",
      },
      {
        className: "bg-1f6a7",
        icon: "🚧",
      },
      {
        className: "bg-1f530",
        icon: "🔰",
      },
      {
        className: "bg-f26fd",
        icon: "⛽",
      },
      {
        className: "bg-1f3ee",
        icon: "🏮",
      },
      {
        className: "bg-f2668",
        icon: "♨",
      },
      {
        className: "bg-1f5ff",
        icon: "🗿",
      },
      {
        className: "bg-1f3aa",
        icon: "🎪",
      },
      {
        className: "bg-1f3ad",
        icon: "🎭",
      },
      {
        className: "bg-1f4cd",
        icon: "📍",
      },

      {
        className: "bg-1f6a9",
        icon: "🚩",
      },
    ],
  },
];
export { emojiData };
