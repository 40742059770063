<template>
  <template v-if="loginUser.userName !== '未登录'">
    <a-card class="usercard">
      <div class="top">
        <div class="top-item">
          <div class="count">
            {{ loginUser?.postCount }}
          </div>
          <div class="title">文章</div>
        </div>
        <div class="top-item">
          <div class="count">
            {{ loginUser?.viewCount }}
          </div>
          <div class="title">访客</div>
        </div>
        <div class="top-item">
          <div class="count">
            {{ loginUser?.thumbCount }}
          </div>
          <div class="title">点赞</div>
        </div>
        <div class="top-item">
          <div class="count">
            {{ loginUser?.followerCount }}
          </div>
          <div class="title">粉丝</div>
        </div>
      </div>
      <a-divider class="divider" />
      <div class="menu">
        <a
          class="menu-item"
          @click="toPage('/user/center/' + loginUser.id + '/info')"
        >
          <div class="icon">
            <MyIcon type="icon-geekgerenzhongxin1" />
          </div>
          个人中心</a
        >
        <a
          class="menu-item"
          @click="toPage('/user/center/' + loginUser?.id + '/article')"
        >
          <div class="icon">
            <MyIcon type="icon-geekwenzhang" />
          </div>
          文章管理</a
        >
        <a
          class="menu-item"
          @click="toPage('/user/center/' + loginUser?.id + '/resources')"
        >
          <div class="icon">
            <MyIcon type="icon-geektiezi" />
          </div>
          资源管理</a
        >
        <a-divider class="divider" />

        <a class="menu-item" @click="logout">
          <div class="icon">
            <MyIcon type="icon-geektuichudenglu" />
          </div>
          退出登录</a
        >
      </div>
    </a-card>
  </template>
  <template v-else>
    <a-card style="width: 300px; height: 200px; border: none; box-shadow: none">
      <div class="tip">
        <p>登录后你可以：</p>
        <div class="tip-list">
          <div class="tip-item"><MyIcon type="icon-geekwenzhang" /> 写文章</div>
          <div class="tip-item"><MyIcon type="icon-geektiezi" /> 发帖子</div>
          <div class="tip-item"><MyIcon type="icon-geekshequ" /> 加入社区</div>
          <div class="tip-item">
            <MyIcon type="icon-geekwodeguanzhu" /> 关注大佬
          </div>
        </div>
      </div>
      <a @click="toPage('/user/login')" class="toLogin">立即登录</a>
    </a-card>
  </template>
</template>

<script setup lang="ts">
import { h, defineProps, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { MailOutlined, CalendarOutlined } from "@ant-design/icons-vue";
import MyIcon from "@/components/MyIcon.vue";
import type { MenuProps } from "ant-design-vue";
import { UserControllerService } from "../../generated";
import { message } from "ant-design-vue";

const router = useRouter();

const props = defineProps({
  loginUser: {
    type: Object,
    required: true,
  },
});
const toPage = (path: string) => {
  router.push(path);
};
const logout = async () => {
  const res = await UserControllerService.userLogoutUsingPost();
  console.log(res);
  if (res.code === 0) {
    message.success("退出登录");
    localStorage.removeItem("token");
    location.reload();
  } else {
    message.warning(res.message);
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.usercard {
  border-radius: 20px;
  max-width: 260px;
  max-height: 400px;
  padding: 0;
  margin: 0;
  min-width: 200px;
  .top {
    padding: 0;
    display: flex;
    justify-items: center;
    width: 100%;
    text-align: center;
    height: 50px;

    .top-item {
      width: 70px;
      height: 50px;
      margin: 5px;

      div {
        width: 100%;
        height: 50%;
        color: #7a858f;
      }

      .count {
        font-size: 20px;
        font-weight: bold;
        color: black;
      }
    }
  }
  .menu {
    .menu-item {
      display: block;
      width: 100%;
      height: 40px;
      line-height: 30px;
      margin-top: 5px;
      padding: 5px;
      color: #7a858f;
      border-radius: 5px;
    }

    .icon {
      display: inline;
      margin-right: 5px;
      margin-left: 5px;
    }

    .menu-item:hover {
      background: rgba(211, 222, 231, 0.3);
      /*color: #2f80ed;*/
    }
  }
  border: none;
  box-shadow: none;
}

.divider {
  margin: 15px;
}

.tip {
  width: 100%;
  min-width: 200px;
  .tip-list {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .tip-item {
      min-width: 30px;
      flex-basis: 50%; /* 让每个 tip-item 横向占据一半的空间 */
      height: 20px;
      margin-top: 10px; /* 添加间距，使每个 tip-item 之间有一定的间隔 */
      line-height: 20px;
    }
  }
}
.toLogin {
  width: 90%;
  height: 50px;
  display: block;
  background: #2f80ed;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  color: white;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
}
.toLogin:hover {
  background: #4096ff;
  box-shadow: 0 2px 10px rgba(22, 119, 255, 0.5);
}
</style>
